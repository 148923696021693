import { Box, Card, Paper, Typography, Button, useMediaQuery  } from "@mui/material";
import Form from "react-bootstrap/Form";
import React, { useEffect, useRef, useState } from "react";
import TextField from "@mui/material/TextField";
import RefreshIcon from '@mui/icons-material/Refresh';
import { useTheme } from '@mui/material/styles';
import { useSidebar } from "../Context/SidebarContext";
import {useLocation} from "react-router-dom";

export const LandsFilterCard = ({ handleSearch }) => {
  const location = useLocation()
  const defaultFilters = location.state

  const [landsCodeNumber, setLandsCodeNumber] = useState(defaultFilters?.landsCodeNumber ?? "");
  const [zoneAffectation, setZoneAffectation] = useState(defaultFilters?.zoneAffectation ?? "");
  const [commune, setCommune] = useState(defaultFilters?.commune ?? "");
  const [address, setAddress] = useState(defaultFilters?.address ?? "");
  const [owner, setOwner] = useState(defaultFilters?.owner ?? "");
  const [canton, setCanton] = useState(defaultFilters?.canton ?? "");
  const [areaMin, setAreaMin] = useState(defaultFilters?.areaMin ?? null);
  const [areaMax, setAreaMax] = useState(defaultFilters?.areaMax ?? null);
  const [minYear, setMinYear] = useState(defaultFilters?.minYear ?? null);
  const [maxYear, setMaxYear] = useState(defaultFilters?.maxYear ?? null);
  const [minBuildingNbr, setMinBuildingNbr] = useState(defaultFilters?.minBuildingNbr ?? null);
  const [maxBuildingNbr, setMaxBuildingNbr] = useState(defaultFilters?.maxBuildingYear ?? null);
  const theme = useTheme();
  const { isOpen } = useSidebar();

  useEffect(() => {
    onClickValidate()
  }, []);

  const onClickValidate = () => {
    let newFilters = {};
    if (landsCodeNumber) newFilters.landsCodeNumber = landsCodeNumber;
    if (commune) newFilters.commune = commune;
    if (zoneAffectation) newFilters.zoneAffectation = zoneAffectation;
    if (address) newFilters.address = address;
    if (owner) newFilters.owner = owner;
    if (canton) newFilters.canton = canton;
    if (areaMin && areaMax) {
      newFilters.areaMin = areaMin;
      newFilters.areaMax = areaMax;
    }
    if(maxYear) newFilters.maxYear = maxYear
    if(minYear) newFilters.minYear = minYear
    if(minBuildingNbr) newFilters.minBuildingNbr = minBuildingNbr
    if(maxBuildingNbr) newFilters.maxBuildingNbr = maxBuildingNbr

    newFilters.size = 10;
    newFilters.page = 0;

    console.log("new filters", newFilters);

    handleSearch(newFilters);
  };

  const onClickClean = () => {
    setCommune("");
    setAreaMin("");
    setAreaMax("");
    setOwner("");
    setCanton("");
    setAddress("");
    setZoneAffectation("");
    setLandsCodeNumber("");
    setMaxYear("");
    setMinYear("");
    setMinBuildingNbr("");
    setMaxBuildingNbr("");

    handleSearch([]);
  };

  const filterPos = {
    position: "fixed",
    top: "20px",
    width: "22%", 
    maxHeight: "90vh",
    transition: "all 0.3s ease", 
    padding: "20px",  
    backgroundColor: "#fff",    
    '& .MuiFormGroup-root': {
      marginBottom: '1rem'
    },
    '& .MuiTextField-root': {
      marginBottom: '0.5rem'
    },


    [theme.breakpoints.down('lg')]: {
      width: "28%",
      marginRight: "5px"
    },
    [theme.breakpoints.down('md')]: {
      position: "inherit",
      width: "100%"
    },
    [theme.breakpoints.down('sm')]: {
      position: "inherit",
      width: "100%"
    },
  };

  const openFilterPos = {


    position: "fixed",
    top: "20px",
    width: "19%", 
    maxHeight: "90vh",
    transition: "all 0.3s ease", 
    padding: "20px",  
    backgroundColor: "#fff", 
    '& .MuiFormGroup-root': {
      marginBottom: '1rem'
    },
    '& .MuiTextField-root': {
      marginBottom: '0.5rem'
    },

    [theme.breakpoints.down('lg')]: {
      width: "100%",
      position: "inherit"
    },
    [theme.breakpoints.down('md')]: {
      position: "inherit",
      width: "100%"
    },
    [theme.breakpoints.down('sm')]: {
      position: "inherit",
      width: "100%"
    },
  }


  return (
    <Card
    className="shadow-none"
    sx={
      isOpen ? openFilterPos : filterPos
      }
    >
      <Box component="form">
        <Form.Group className="my-2" controlId="1" key="landsCodeNumber">
          <TextField
            type="text"
            controlId="1"
            label="N° Parcelle"
            placeholder="N° Parcelle"
            fullWidth
            value={landsCodeNumber}
            onChange={(e) => setLandsCodeNumber(e.target.value)}
            size="small"
          />
        </Form.Group>
        <Form.Group className="my-2" controlId="5" key="Commune">
          <TextField
            type="text"
            controlId="5"
            label="Commune"
            placeholder="Commune"
            fullWidth
            value={commune}
            onChange={(e) => setCommune(e.target.value)}
            size="small"
          />
        </Form.Group>
        <Form.Group className="my-2" controlId="51" key="Canton">
          <TextField
              type="text"
              controlId="5"
              label="Canton"
              placeholder="Canton"
              fullWidth
              value={canton}
              onChange={(e) => setCanton(e.target.value)}
              size="small"
          />
        </Form.Group>
        <Form.Group className="my-2" controlId="3" key="address">
          <TextField
            type="text"
            controlId="3"
            label="Adresse"
            placeholder="Adresse"
            fullWidth
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            size="small"
          />
        </Form.Group>
        <Form.Group className="my-2" controlId="52" key="zoneAffectation">
          <TextField
            type="text"
            controlId="5"
            label="Zone d'affectation"
            placeholder="Zone d'affectation"
            fullWidth
            value={zoneAffectation}
            onChange={(e) => setZoneAffectation(e.target.value)}
            size="small"
          />
        </Form.Group>
        <Form.Group className="my-1" controlId="6" key="owner">
          <TextField
            type="text"
            controlId="6"
            label="Propriétaire"
            placeholder="Propriétaire"
            fullWidth
            value={owner}
            onChange={(e) => setOwner(e.target.value)}
            size="small"
          />
        </Form.Group>
        <Form.Group className="my-1" controlId="61" key="nombre de batiment">
          <Form.Label>Nombre de batiment</Form.Label>
          <Box sx={{display: "flex", justifyContent: "space-between"}}>
            <TextField
                type="number"
                controlId="6"
                label="min"
                placeholder="min"
                InputProps={{ inputProps: { min: 1 } }}
                fullWidth
                value={minBuildingNbr}
                onChange={(e) => setMinBuildingNbr(e.target.value)}
                sx={{ mb: 1, width: "47%" }}
                size="small"
            />
            <TextField
                type="number"
                controlId="6"
                label="max"
                placeholder="max"
                InputProps={{ inputProps: { min: 2 } }}
                fullWidth
                value={maxBuildingNbr}
                onChange={(e) => setMaxBuildingNbr(e.target.value)}
                sx={{width: "47%"}}
                size="small"
            />
          </Box>
          <Form.Label>Date de construction</Form.Label>
          <Box sx={{display: "flex", justifyContent: "space-between"}}>
            <TextField
                type="number"
                controlId="6"
                label="min"
                placeholder="min"
                InputProps={{ inputProps: { min: 1 } }}
                fullWidth
                value={minYear}
                onChange={(e) => setMinYear(e.target.value)}
                sx={{ mb: 1, width: "47%" }}
                size="small"
            />
            <TextField
                type="number"
                controlId="6"
                label="max"
                placeholder="max"
                InputProps={{ inputProps: { min: 2 } }}
                fullWidth
                value={maxYear}
                onChange={(e) => setMaxYear(e.target.value)}
                sx={{width: "47%"}}
                size="small"
            />
          </Box>
        </Form.Group>
        <Form.Group controlId="6" key="area">
          <Form.Label>Surface</Form.Label>
        <Box sx={{display: "flex", justifyContent: "space-between"}}>
          <TextField
            type="number"
            controlId="6"
            label="min"
            placeholder="min"
            InputProps={{ inputProps: { min: 1 } }}
            fullWidth
            value={areaMin}
            onChange={(e) => setAreaMin(e.target.value)}
            sx={{ mb: 1, width: "47%" }}
            size="small"
          />
          <TextField
            type="number"
            controlId="6"
            label="max"
            placeholder="max"
            InputProps={{ inputProps: { min: 2 } }}
            fullWidth
            value={areaMax}
            onChange={(e) => setAreaMax(e.target.value)}
            sx={{width: "47%"}}
            size="small"
          />
        </Box>
        </Form.Group>
        <Box sx={{ display: "flex", justifyContent: "flex-end", pt: 2 }}>

        <Button
          sx={{
            background: "#757575",
            color: "#fff",
            "&:hover": { background: "#757575" },
            display:"flex",
            alignItems: "center",
            justifyContent: "space-between",
            verticalAlign: "center"
          }}
          onClick={() => onClickClean()}
        >
          <RefreshIcon sx={{mr: 1, fontSize: 18}} />
          REINITIALISER
        </Button>
        <Button
          sx={{
            background: "#299CDB",
            color: "#fff",
            "&:hover": { background: "#299CDB" },
            ml: 1,
          }}
          onClick={() => onClickValidate()}
        >
          Filtrer
        </Button>
        </Box>
      </Box>
    </Card>
  );
};
