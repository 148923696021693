import { GoogleMap, LoadScript, MarkerF } from "@react-google-maps/api";

const MapsLayout = ({ lat, long, name }) => {
  const apiKey = "AIzaSyBav4VWXM20D6RGGbuDuMvPDG2wIu1wbWs";
  const mapStyles = {
    height: "53vh",
  };  

  return (
    <LoadScript googleMapsApiKey={apiKey}>
    <div style={mapStyles}>
      <GoogleMap
        mapContainerStyle={mapStyles}
        zoom={19}
        center={{ lat: lat, lng: long }}
        mapTypeId="satellite"
      >
        <MarkerF position={{ lat: lat, lng: long }} />
      </GoogleMap>
    </div>
    </LoadScript>
  );
};    

export default MapsLayout;
