import React, { useEffect, useState } from "react";
import proj4 from "proj4";
import { detailparcelleStyles } from "./styles";
import { Box, Link, CircularProgress } from "@mui/material";
import { useApplication } from "../../../hooks/UseApplication";
import DownloadIcon from '@mui/icons-material/Download';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { searchLocations } from "../../../service/geoApiService";

const DetailInfos = () => {
  const [activeTab, setActiveTab] = useState("resume");
  const { details } = useApplication();
  const [modifiedGeoPortaLink, setModifiedGeoPortaLink] = useState("");
  
  // Définir les projections
  const wgs84 = "EPSG:4326";
  const lv95 = "EPSG:2056";

  const [mn95X, setMn95X] = useState(null);
  const [mn95Y, setMn95Y] = useState(null);
  const [batiments, setBatiments] = useState([]);

  const [solarPotentialUrl, setSolarPotentialUrl] = useState("");
  const [isSolarPotentialLoading, setIsSolarPotentialLoading] = useState(false);
  const [solarPotentialError, setSolarPotentialError] = useState("");



  useEffect(() => {
    console.log("details in info.jsx ", details);
    if (details) {
      // Ajouter la définition de la projection LV95 (CH1903+/LV95)
      proj4.defs(
        lv95,
        "+proj=somerc +lat_0=46.95240555555556 +lon_0=7.439583333333333 +k=1 +x_0=2600000 +y_0=1200000 +ellps=bessel +datum=ch1903 +units=m +no_defs"
      );

      // Convertir les coordonnées
      const [tempMn95X, tempMn95Y] = proj4(wgs84, lv95, [
        details.geo_center.lon,
        details.geo_center.lat,
      ]);
      setMn95X(tempMn95X);
      setMn95Y(tempMn95Y);

      // Construire le lien avec les coordonnées MN95 arrondies
      if (details.geo_portal_link) {
        setModifiedGeoPortaLink(
          details.geo_portal_link
            .replace("{x}", Math.round(mn95X))
            .replace("{y}", Math.round(mn95Y))
        );
      }
    }

    if (details && details.buildings && details.buildings.length > 0) {
      setBatiments(
        details.buildings.map((batiment) => batiment.buildings_administrative)
      );
    }
  }, [details]);

  const calculateSolarPotential = async () => {
    console.log("lat", details);

    if (!details.geo_center.lat || !details.geo_center.lon) {
      setSolarPotentialError("Coordonnées manquantes");
      return;
    }

    setIsSolarPotentialLoading(true);
    setSolarPotentialError("");

    try {
 
      const [x, y] = proj4(wgs84, lv95, [details.geo_center.lon, details.geo_center.lat]);
      
      const result = await searchLocations(
        details.geo_center.lat,
        details.geo_center.lon
      );

      console.log('result >>' , result)

      if (result && result.featureId) {
        const url = `https://www.uvek-gis.admin.ch/BFE/sonnendach/index.html?featureId=${result.featureId}&lang=fr`;
        setSolarPotentialUrl(url);
      } else {
        setSolarPotentialError("Données d'ensoleillement non disponibles");
      }
    } catch (error) {
      console.error("Erreur lors du calcul du potentiel solaire:", error);
      setSolarPotentialError("Erreur lors de la récupération des données");
    } finally {
      setIsSolarPotentialLoading(false);
    }
  };

  useEffect(() => {
    if (details.id) {
      calculateSolarPotential();
    }
  }, [details.id]);

  return (
    <Box
      className="Layout bg-white my-4 rounded"
      sx={{
        display: "flex",
        height: "auto",
        flexWrap: "wrap",
        flexDirection: {
          xl: "row",
          lg: "row",
          md: "row",
          sm: "column-reverse",
          xs: "column-reverse",
        },
        borderTopRightRadius: "8px",
        borderTopLeftRadius: { lg: 0, md: 0, sm: "8px", xs: "8px" },
        justifyContent: {
          lg: "inherit",
          md: "inherit",
          sm: "flex-end",
          xs: "flex-end",
        },
      }}
    >
      <Box
        className="container-fluid"
        sx={
          activeTab === "zone" || activeTab === "batiment"
            ? { width: "84%" }
            : {
                width: {
                  xl: "100%",
                  lg: "100%",
                  md: "100%",
                  sm: "100%",
                  xs: "100%",
                },
              }
        }
      >
        <Box className="row">
          <Box className="col-12 px-4 p-4 pb-0">
            <h4 style={detailparcelleStyles.fontWeightTitle}>INFORMATIONS</h4>
            <Box
              className="d-flex justify-content-between p-4 overflow-auto"
              style={detailparcelleStyles.grayBg}
            >
              <Box className="mx-2" sx={detailparcelleStyles.cellWdth}>
                <p>
                  <span
                    style={{
                      color: detailparcelleStyles.graylight,
                      ...detailparcelleStyles.fontWeightTitle,
                    }}
                  >
                    N° parcelle :
                  </span>{" "}
                  {details.code_number}
                </p>
                <p>
                  <span
                    style={{
                      color: detailparcelleStyles.graylight,
                      ...detailparcelleStyles.fontWeightTitle,
                    }}
                  >
                    Type :
                  </span>{" "}
                  {details.type === "private" ? "Privé" : "Public"}
                </p>
                <p>
                  <span
                    style={{
                      color: detailparcelleStyles.graylight,
                      ...detailparcelleStyles.fontWeightTitle,
                    }}
                  >
                    Type de plan :
                  </span>{" "}
                  {details.main_lupa_type}
                </p>
                <p>
                  <span
                    style={{
                      color: detailparcelleStyles.graylight,
                      ...detailparcelleStyles.fontWeightTitle,
                    }}
                  >
                    Adresse :
                  </span>{" "}
                  {details.address_full}
                </p>
                <p>
                  <span
                    style={{
                      color: detailparcelleStyles.graylight,
                      ...detailparcelleStyles.fontWeightTitle,
                    }}
                  >
                    Commune OFS n<sup>°</sup> :
                  </span>{" "}
                  {details.city_ofs_number}
                </p>
              </Box>
              <Box className="mx-2" sx={detailparcelleStyles.cellWdth}>
                <p>
                  <span
                    style={{
                      color: detailparcelleStyles.graylight,
                      ...detailparcelleStyles.fontWeightTitle,
                    }}
                  >
                    Surface parcelle :
                  </span>{" "}
                  {details.area} m<sup>2</sup>
                </p>
                <p>
                  <span
                    style={{
                      color: detailparcelleStyles.graylight,
                      ...detailparcelleStyles.fontWeightTitle,
                    }}
                  >
                    Surface bâtiment :
                  </span>{" "}
                  {details.building_total_area} m<sup>2</sup>
                </p>
                <p>
                  <span
                    style={{
                      color: detailparcelleStyles.graylight,
                      ...detailparcelleStyles.fontWeightTitle,
                    }}
                  >
                    Etage max :
                  </span>{" "}
                  {details.max_floor_nb}
                </p>
                <p>
                  <span
                    style={{
                      color: detailparcelleStyles.graylight,
                      ...detailparcelleStyles.fontWeightTitle,
                    }}
                  >
                    Hauteur max :
                  </span>{" "}
                  {details.max_height
                    ? `${details.max_height} m`
                    : "Non spécifié"}
                </p>
                <p>
                  <span
                    style={{
                      color: detailparcelleStyles.graylight,
                      ...detailparcelleStyles.fontWeightTitle,
                    }}
                  >
                    Nom Zone :
                  </span>{" "}
                  {details.main_lupa_name}
                </p>
              </Box>
              <Box className="mx-2" sx={detailparcelleStyles.cellWdth}>
                <p>
                  <span
                    style={{
                      color: detailparcelleStyles.graylight,
                      ...detailparcelleStyles.fontWeightTitle,
                    }}
                  >
                    EGRID :
                  </span>{" "}
                  {details.egrid_nb}
                </p>
                {details.rf_uri === "" || (
                  <p>
                    <span
                      style={{
                        color: detailparcelleStyles.graylight,
                        ...detailparcelleStyles.fontWeightTitle,
                      }}
                    >
                      Registre Foncier :
                    </span>
                     
                    {details.rf_uri ? (
                      <Link
                        href={details.rf_uri}
                        target="_blank"
                        rel="noreferrer"
                        sx={{ fontStyle: "none" }}
                      >
                        Voir
                      </Link>
                    ) : (
                      ""
                    )}
                  </p>
                )}
                {modifiedGeoPortaLink === "" || (
                  <p>
                    <span
                      style={{
                        color: detailparcelleStyles.graylight,
                        ...detailparcelleStyles.fontWeightTitle,
                      }}
                    >
                      Géoportail :
                    </span>
                     
                    {modifiedGeoPortaLink ? (
                      <Link
                        href={modifiedGeoPortaLink}
                        target="_blank"
                        rel="noreferrer"
                        sx={{ fontStyle: "none" }}
                      >
                        Voir
                      </Link>
                    ) : (
                      ""
                    )}
                  </p>
                )}
                <p>
                  <span
                    style={{
                      color: detailparcelleStyles.graylight,
                      ...detailparcelleStyles.fontWeightTitle,
                    }}
                  >
                    Cadastre :
                  </span>
                   
                  {details.cadastreLink ? (
                    <Link
                      href={details.cadastreLink}
                      target="_blank"
                      rel="noreferrer"
                      sx={{ fontStyle: "none" }}
                    >
                      Télécharger
                    </Link>
                  ) : (
                    ""
                  )}
                </p>
                <p>
                  <span
                    style={{
                      color: detailparcelleStyles.graylight,
                      ...detailparcelleStyles.fontWeightTitle,
                    }}
                  >
                    Ensoleillement:
                  </span>
                   
                  {isSolarPotentialLoading ? (
                    <CircularProgress size={20} sx={{ ml: 1 }} />
                  ) : solarPotentialError ? (
                    <span style={{  marginLeft: "8px" }}>
                      Indisponible
                    </span>
                  ) : solarPotentialUrl ? (
                    <Link
                      href={solarPotentialUrl}
                      target="_blank"
                      rel="noreferrer"
                      sx={{ ml: 1 }}
                    >
                      Voir
                    </Link>
                  ) : (
                    <span style={{ marginLeft: "8px" }}>Aucune donnée</span>
                  )}
                </p>
              </Box>
            </Box>
          </Box>
          <Box className="row p-4">
            {batiments && batiments.length > 0 ? (
              batiments &&
              batiments.map((batiment, index) => (
                <Box className="pt-4 col-md-4" key={index}>
                  <h5 style={detailparcelleStyles.fontWeightTitle}>
                    BATIMENT {index + 1}
                  </h5>
                  <Box
                    className="d-flex justify-content-between p-4 overflow-auto"
                    style={detailparcelleStyles.grayBg}
                  >
                    {batiment && batiment[0] && (
                      <Box
                        className="me-2"
                        style={detailparcelleStyles.cellWdth}
                      >
                        <p>
                          <span
                            style={{
                              color: detailparcelleStyles.graylight,
                              ...detailparcelleStyles.fontWeightTitle,
                            }}
                          >
                            Classe bâtiments :
                          </span>{" "}
                          {batiment[0].category}
                        </p>
                        <p>
                          <span
                            style={{
                              color: detailparcelleStyles.graylight,
                              ...detailparcelleStyles.fontWeightTitle,
                            }}
                          >
                            Année de contruction :
                          </span>{" "}
                          {batiment[0].building_period}
                        </p>
                        <p>
                          <span
                            style={{
                              color: detailparcelleStyles.graylight,
                              ...detailparcelleStyles.fontWeightTitle,
                            }}
                          >
                            Numéro Officiel bâtiments :
                          </span>{" "}
                          {batiment[0].egid ? (
                            <Link
                              href={
                                "https://www.housing-stat.ch/fr/query/egid.html?egid=" +
                                batiment[0].egid
                              }
                              target="_blank"
                              rel="noreferrer"
                              sx={{ fontStyle: "none" }}
                            >
                              Voir{" "}
                            </Link>
                          ) : null}
                        </p>
                      </Box>
                    )}
                  </Box>
                </Box>
              ))
            ) : (
              <Box className="mt-4 p-4" style={detailparcelleStyles.grayBg}>
                Pas de bâtiments disponible
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DetailInfos;
