import React, { useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import { Box } from "@mui/material";
import Grid from "@mui/system/Unstable_Grid/Grid";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Nav } from "react-bootstrap";
import { ViewList } from "@mui/icons-material";
import CustomBtn from "../component/customBtns/CustomBtn";
import jwtInterceptor from "../service/jwtInterceptor";
import { LAND_ENDPOINTS } from "../utils/api";
import { useToast } from "../Context/ToastContext";
import { MyLandsMapFilterCard } from "../component/MyLandsMapFilterCard";

const MyLandMapsContainer = (props) => {
  const DEFAULT_PER_PAGE = 10;
  const DEFAULT_PAGE = 0;
  const [filters, setFilters] = useState({
    parcelNumber: "",
    commune: "",
    address: "",
    zone: "",
    owner: "",
    surface: "",
    constructionDate: "",
  });
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_PER_PAGE);
  const [total, setTotal] = useState(0);
  const [dataTable, setDataTable] = useState([]);
  const [searchForm, setSearchForm] = useState({
    zoneNoBuilding: "all",
    zoneSpecial: "all",
    areaMin: null,
    areaMax: null,
    isBareLand: false,
    buildingAreaMin: null,
    buildingAreaMax: null,
    buildingYearMin: null,
    buildingYearMax: null,
    sansDate: false,
    renovationMin: null,
    renovationMax: null,
    renovationFinised: false,
    nbNiveauMin: null,
    nbNiveauMax: null,
    logementMin: null,
    logementMax: null,
    propertyOwner: null,
    propertyType: "all",
    proprioForm: "all",
    term: "",
    lands: [],
    location: [],
    owners: [],
    nbProprioMin: null,
    nbProprioMax: null,
    history: "all",
    lastBuildPermit: "all",
    size: 10,
    page: 0,
    region: "all",
  });
  const [loading, setLoading] = useState(true);
  const [modalShow, setModalShow] = useState(false);

  const toaster = useToast();

  const mapsLayout = {
    display: "block",
    height: "100%",
    width: "100%",
    position: "absolute",
    p: 4,
  };

  const switchBtn = {
    position: "fixed",
    top: "2%",
    right: "1%",
    zIndex: 9999
  };

  const handleKeyUp = (event) => {
    if (event.key === "Enter") {
      searchLocationFn();
    }
  };

  const searchfn = async (filter) => {
    setFilters(filter);
    if (filter.page !== undefined) setPage(filter.page);
    if (filter.size) setRowsPerPage(filter.size);
    return await jwtInterceptor
      .post(`${LAND_ENDPOINTS.searchMyLands}`, filter)
      .then((res) => {
        setTotal(res.data.data.total);
        setDataTable(res.data.data.results);
        setLoading(false);
      })
      .catch((error) => {
        toaster.notifyError(error.message);
      });
  };

  const searchLocationFn = async () => {
    setLoading(true);
    return await jwtInterceptor
      .post(`${LAND_ENDPOINTS.searchMyLands}`, searchForm)
      .then((res) => {
        setTotal(res.data.data.total);
        setDataTable(res.data.data.results);
        setLoading(false);
      })
      .catch((error) => {
        toaster.notifyError(error.message);
      });
  };

  const getData = (perPage, page, favoriteOnly = false) => {
    searchfn({
      ...filters,
      page,
      size: perPage,
      favoritesOnly: favoriteOnly,
    });
  };

  const getDataFiltered = () => {
    searchfn(searchForm).then(() => setModalShow(false));
  };

  useEffect(() => {
    jwtInterceptor
      .post(`${LAND_ENDPOINTS.searchMyLands}`, {
        ...searchForm,
        size: DEFAULT_PER_PAGE,
        page: DEFAULT_PAGE,
      })
      .then((res) => {
        setTotal(res.data.data.total);
        setDataTable(res.data.data.results);
        setLoading(false);
      })
      .catch((error) => {
        console.log("error in useEffect");
        toaster.notifyError(error.message);
      });
  }, []);

  return (
    <Box className="w-100" sx={{ height: "101vh", position: "relative" }}>
      <Box className="maps-layout" sx={mapsLayout}>
        <Nav.Link as={Link} to="/land"  >
          <CustomBtn
            label="Liste"
            bgColor="#299CDB"
            iconBg={"#3ba9e5"}
            icon={<ViewList />}
            styles={{ float: "right", ...switchBtn }}
          />
        </Nav.Link>
      </Box>
      <Helmet>
        <title>My Land Maps - Mixdata</title>
      </Helmet>
      <Grid container spacing={2} sx={{ height: "100%" }}>
        <Grid
          className="filters shadow-none"
          xl={3}
          sx={{ position: "absolute" }}
        >
          <Paper className="shadow-none  m-4" style={{ height: "auto" }}>
            <MyLandsMapFilterCard />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MyLandMapsContainer;
