import { Badge, Box, Chip, Link } from "@mui/material"
import Enquete from '../../../assets/Images/icons/enQt.svg'
import { useEffect } from "react"
import { useTransactionsAndFaos } from "../../../Context/crm/TransactionsAndFaosContext"
import CustomBtn from "../../customBtns/CustomBtn"
import BiotechOutlinedIcon from '@mui/icons-material/BiotechOutlined';


const EnquetesBadge = ({onClick}) => {
    const {faos} = useTransactionsAndFaos()

    useEffect(() => {
        console.log('faos in faos badge : ', faos)
    })
    return (
        <Box sx={{marginRight: "15px"}}>
            <Link to="#enquete" onClick={onClick} sx={{cursor: "pointer" , marginInline: {xl : 0 , lg: 0 , md: 0 , sm: 0 ,xs:1}}}>
            <Badge badgeContent={faos.length > 0 ? faos.length : '0'} color="error" size="small"   anchorOrigin={{  vertical: 'top', horizontal: 'left', }}>
            <CustomBtn
                          bgColor={"#3F86B6"}
                          label="Enquêtes"
                          iconBg={"#3F86B6"}
                          icon={<BiotechOutlinedIcon />}
                      />
            </Badge>
            </Link>

        </Box>
    )
}

export default EnquetesBadge