export const Localstorage = () => {
  const ITEM = "MIXDATA_STATE"
  return {
    isAuth: () => {
      return localStorage.getItem(ITEM)
    },
    authenticate: (item) => {
      localStorage.setItem(ITEM, JSON.stringify(item))
    },
    setToken: (token) => {
      const auth = JSON.parse(localStorage.getItem(ITEM))
      console.log('auth in set token', auth)
      auth.token = token
      localStorage.setItem(ITEM, JSON.stringify(auth))
    },
    clearAuth: () => {
      localStorage.removeItem(ITEM)
    }
  }
}
