import React from "react";
import { useApplication } from "../../../hooks/UseApplication";
import MapsLayout from "../../MapsLayout";
import { detailparcelleStyles } from "./styles";
import { Box } from "@mui/material";

/**** Svgs && icons */
import TimerIcon from "@mui/icons-material/Timer";
import { SquareFoot } from "@mui/icons-material";
import { Apartment } from "@mui/icons-material";
import { Terrain } from "@mui/icons-material";

const DetailMap = () => {
  const { details } = useApplication();
  const resumeStyle = {
    width: { xl: "100%", lg: "100%", md: "100%", sm: "100%", xs: "100%" },
    borderRadius: "8px",
    // filter: "drop-shadow(0px 4px 4px rgba(230, 230, 230, 0.25))",
    display: "flex",
    justifyContent: "center",
    background: "white",
    flexWrap: "wrap",
    paddingInline: {xl:"20px" , lg: "0px" , md: "0px" , sm: "0px"}
  };
  const responsiveMarginLayout = {
    mt: "45px",
    "@media(max-width: 768px)": {
      mt: "112px",
    },
  };

  return (
    <Box className="col-lg-12 px-4 pb-3" sx={responsiveMarginLayout}>
      <Box
        sx={{ position: "relative", mb: { xl: 0, lg: 0, md: 0, sm: 0, xs: 0 } }}
      >
        <Box className="" style={detailparcelleStyles.mapsLayout}>
          <MapsLayout
            lat={details.geo_center ? details.geo_center.lat : 0}
            long={details.geo_center ? details.geo_center.lon : 0}
            name={details.address_full || ""}
          />
        </Box>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between",  mt: "10px " }}>
        <Box sx={resumeStyle}>
          <Box
            className="col-6 col-lg-6 col-md-6 col-sm-6 col-xl col-xl d-flex justify-content-center flex-wrap  py-0 px-2"
            sx={{
              borderRight: {
                xl: "2px solid rgba(217, 217, 217, 0.35)",
                lg: "2px solid rgba(217, 217, 217, 0.35)",
                md: "2px solid rgba(217, 217, 217, 0.35)",
                sm: "2px solid rgba(217, 217, 217, 0.35)",
                xs: "none",
              },
              borderBottom: {
                xl: "inherit",
                lg: "inherit",
                md: "inherit",
                sm: "inherit",
                xs: "none",
              },
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", width: "100%", justifyContent: "center" }}>
              <Box sx={{ display: "flex", alignItems: "center" , width: "auto"}}>
                <SquareFoot
                  className="fluid"
                  style={{ ...detailparcelleStyles.logoWidth, fontSize: 30 }}
                />
                <p
                  className="w-100 text-left mb-0  ms-2 me-2"
                  style={{
                    ...detailparcelleStyles.fontWeightTitle,
                    ...detailparcelleStyles.textBlueLight,
                  }}
                >
                  Surface :
                </p>
              </Box>
              <p
                className=" text-left mb-0 inline-block w-auto fs-6"
                style={detailparcelleStyles.fontWeightTitle}
              >
                {details.area} m
                <sup style={detailparcelleStyles.fontWeightTitle}>2</sup>
              </p>
            </Box>
          </Box>
          <Box
            className="col-6 col-lg-6 col-md-6 col-sm-6 col-xl d-flex justify-content-center flex-wrap  py-0 px-2"
            sx={{
              borderRight: {
                xl: "2px solid rgba(217, 217, 217, 0.35)",
                lg: "none",
                md: "none",
                sm: "none",
                xs: "none",
              },
              borderBottom: {
                xl: "inherit",
                lg: "inherit",
                md: "inherit",
                sm: "inherit",
                xs: "none",
              },
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", width: "100%", justifyContent: "center" }}>
              <Box sx={{ display: "flex", alignItems: "center" , width: "auto"}}>
                <TimerIcon
                  className="fluid"
                  style={{ ...detailparcelleStyles.logoWidth, fontSize: 30 }}
                />
                <p
                  className="w-100 text-left mb-0  ms-2 me-2"
                  style={{
                    ...detailparcelleStyles.fontWeightTitle,
                    ...detailparcelleStyles.textBlueLight,
                  }}
                >
                  Années :
                </p>
              </Box>
              <p
                className=" text-left mb-0 inline-block w-auto fs-6"
                style={detailparcelleStyles.fontWeightTitle}
              >
                {details.average_building_year}
              </p>
            </Box>
          </Box>
          <Box
            className="col-6 col-lg-6 col-md-6 col-sm-6 col-xl d-flex justify-content-center flex-wrap  py-0 px-2"
            sx={{
              borderRight: {
                xl: "2px solid rgba(217, 217, 217, 0.35)",
                lg: "2px solid rgba(217, 217, 217, 0.35)",
                md: "2px solid rgba(217, 217, 217, 0.35)",
                sm: "2px solid rgba(217, 217, 217, 0.35)",
                xs: "none",
              },
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", width: "100%", justifyContent: "center" }}>
              <Box sx={{ display: "flex", alignItems: "center" , width: "auto"}}>
                <Apartment
                  className="fluid"
                  style={{ ...detailparcelleStyles.logoWidth, fontSize: 30 }}
                />
                <p
                  className="w-100 text-left mb-0  ms-2 "
                  style={{
                    ...detailparcelleStyles.fontWeightTitle,
                    ...detailparcelleStyles.textBlueLight,
                  }}
                >
                  Bâtiment(s) :
                </p>
              </Box>
              {details.buildings && details.buildings.length && (
                <p
                  className=" text-left mb-0 ms-2 inline-block w-auto fs-6"
                  style={detailparcelleStyles.fontWeightTitle}
                >
                  {details.buildings.length}
                </p>
              )}
            </Box>
          </Box>
          <Box className="col-6 col-lg-6 col-md-6 col-sm-6 col-xl d-flex justify-content-center flex-wrap  py-0 px-2">
            <Box sx={{ display: "flex", alignItems: "center", width: "100%", justifyContent: "center" }}>
              <Box sx={{ display: "flex", alignItems: "center" , width: "auto"}}>
                <Terrain
                  className="fluid"
                  style={{ ...detailparcelleStyles.logoWidth, fontSize: 30 }}
                />
                <p
                  className="w-100 text-left mb-0 ms-2 me-2"
                  style={{
                    ...detailparcelleStyles.fontWeightTitle,
                    ...detailparcelleStyles.textBlueLight,
                  }}
                >
                  Région :
                </p>
              </Box>
              <p
                className=" text-left mb-0 inline-block w-auto fs-6"
                style={detailparcelleStyles.fontWeightTitle}
              >
                {details.region_name}
              </p>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DetailMap;
