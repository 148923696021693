import { USER_ENDPOINTS } from "../utils/api";
import jwtInterceptor from "./jwtInterceptor";

class UserService {
    async getAllUsers(){
        return await jwtInterceptor.get(USER_ENDPOINTS.getPerPage).then((response) => {
            
            if(response.status.toString().startsWith("2")){

                return response.data
            }else{
                throw new Error(response.data.message)
            }
        })
        .catch((error) => {
            throw new Error(error.message)
        })
    }
}

export const userService = new UserService();