import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class CustomToast {
  defaultOptions = {
    autoClose: 1500
  }


  notifySuccess(message) {
    console.log('toast default options', this.defaultOptions)
    toast.success(message, this.defaultOptions);
  }

  notifyError(message) {
    toast.error(message, this.defaultOptions);
  }

  
}

export function createCustomToast() {
  return new CustomToast();
}
