import { NOTE_ENDPOINTS } from "../utils/api";
import jwtInterceptor from "./jwtInterceptor";

class NoteService {
    async findNoteByLandId(landId){
        return jwtInterceptor.get(NOTE_ENDPOINTS.getByLandId.replace(':landId', landId))
        .then((response) => {
            if(!response || !response.data || !response.data.data || !response.data.data.records) throw new Error('erreur durant recuperation des notes')
            return response.data.data.records
        })
        .catch((error) => {
            throw new Error(error.message)
        })
    }

    async deleteNote(noteId){
        return jwtInterceptor.delete(NOTE_ENDPOINTS.delete.replace(':noteId', noteId))
            .then((response) => {
                if(!response || !response.data || !response.data.data || !response.data.data.isDeleted) throw new Error('erreur durant la suppression d\'une note')
                return response.data.data.isDeleted
            })
            .catch((error) => {
                throw new Error(error.message)
            })
    }
}

export const noteService = new NoteService()