
import axios from "axios";
import { Localstorage } from "./Localstorage";
import { API_URL } from "@utils/constants";
import { useApplication } from "../hooks/UseApplication";
import globalRouter from "../utils/globalRouter";

const jwtInterceptor = axios.create({});
const auth = Localstorage().isAuth()
const authObj = JSON.parse(auth)

const logout = async () => {
    console.log('inside logout')
    Localstorage().clearAuth()
    globalRouter.navigate("/login");
  };

jwtInterceptor.interceptors.request.use((config) => {
  if (auth) {
    const authData = authObj.token
    config.headers["x-access-token"] = authData
    return config;
  }
});

jwtInterceptor.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    
    if (error && error.response && error.response.status === 401 && !error.config._retry) {
      
      const rememberMeString = localStorage.getItem('rememberMe')
      
      if(rememberMeString && JSON.parse(rememberMeString) === true){
        const authData = JSON.parse(auth).refresh
        const payload = {
          refreshToken: authData
        };
  
        return await axios.post(
         `${API_URL}/auth/refresh`,
          payload
        ).then((apiResponse) => {
          if (!apiResponse.data.isError) {
            console.log('apiResponse.data', apiResponse.data)
            const token = apiResponse.data.data.details.accessToken
            Localstorage().setToken(token);
            error.config.headers[
              "x-access-token"
            ] = token;
            return axios(error.config);
          } else {
            console.log('inside else')
            logout()
          }
            
        })
        .catch((error) => {
          logout()
        });
  
      }else{
        logout()
      }


    } else {
      return Promise.reject(error);
    }
  }
);

export default jwtInterceptor;
