import React, { useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@mui/material/TablePagination";
import Table from "react-bootstrap/Table";

import TableRowLand from "../component/TableRowLand";
import jwtInterceptor from "../service/jwtInterceptor";
import MapsLayout from "../component/MapsLayout";
import FilterModal from "../component/FilterModal";
import { Checkbox, CircularProgress } from "@mui/material";
import { useToast } from "../Context/ToastContext";
import { TABLE_ROW_ACTIONS } from "../utils/constants";
import { LAND_ENDPOINTS } from "../utils/api";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/system/Unstable_Grid/Grid";

import CustomFilters from "../component/customFilters/customFilters";
import { Box, Typography } from "@material-ui/core";
import { LandsFilterCard } from "../component/LandsFilterCard";
import ParcelResume from "../component/parcelResume/ParcelResume";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Nav } from "react-bootstrap";
import CustomBtn from "../component/customBtns/CustomBtn";
import { Explore } from "@mui/icons-material";

const InputedStyle = {
  height: "37px",
  backgroundColor: "#F3F3F9",
  border: "none",
  borderRadius: "5px",
  maxWidth: "287px",
  width: "100%",

  "@media(max-width: 500px)": {
    ml: "0!important",
  },
};
const iconColor = {
  fill: "#8E91A0",
  height: "20px",
  width: "20px",
};

const MyLandContainer = (props) => {
  const DEFAULT_PER_PAGE = 10;
  const DEFAULT_PAGE = 0;

  const toaster = useToast();

  const [total, setTotal] = useState(0);
  const [dataTable, setDataTable] = useState([]);
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_PER_PAGE);
  const [checkedFavorite, setCheckedFavorite] = useState(false);
  const [checkedArchive, setCheckedArchive] = useState(false);
  const [selectedLandId, setSelectedLandId] = useState(null);
  const [showResume, setShowResume] = useState(false);

  const [searchForm, setSearchForm] = useState({
    zoneNoBuilding: "all",
    zoneSpecial: "all",
    areaMin: null,
    areaMax: null,
    isBareLand: false,
    buildingAreaMin: null,
    buildingAreaMax: null,
    buildingYearMin: null,
    buildingYearMax: null,
    sansDate: false,
    renovationMin: null,
    renovationMax: null,
    renovationFinised: false,
    nbNiveauMin: null,
    nbNiveauMax: null,
    logementMin: null,
    logementMax: null,
    propertyOwner: null,
    propertyType: "all",
    proprioForm: "all",
    term: "",
    lands: [],
    location: [],
    owners: [],
    nbProprioMin: null,
    nbProprioMax: null,
    history: "all",
    lastBuildPermit: "all",
    size: 10,
    page: 0,
    region: "all",
  });
  const [loading, setLoading] = useState(true);
  const [clicked, setClicked] = useState(false);
  const [clickShow, setClickShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  const [filters, setFilters] = useState({
    parcelNumber: "",
    commune: "",
    address: "",
    zone: "",
    owner: "",
    surface: "",
    constructionDate: "",
  });

  const colums = [
    { accessor: "code_number", show: "Parcelle" },
    { accessor: "area", show: "Surface" },
    { accessor: "address_full", show: "Adresse" },
    { accessor: "city_name", show: "Commune" },
    { accessor: "region_name", show: "Canton" },
    { accessor: "principal_type", show: "Zone d'affectation" },
    { accessor: "building_nb", show: "Propriétaire(s)" },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getData(rowsPerPage, newPage, checkedFavorite, checkedArchive);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    getData(event.target.value, page, checkedFavorite, checkedArchive);
  };

  const toggleResume = () => {
    setShowResume((prevShowResume) => !prevShowResume);
  };

  const handleOnClickRow = (landId) => {
    setSelectedLandId(landId);
    toggleResume();
  };

  const onRowChanges = () => {
    searchfn(filters);
  };

  const searchfn = async (filter) => {
    setLoading(true);

    if (filter.favoritesOnly === undefined)
      filter.favoritesOnly = checkedFavorite;
    if (filter.isArchived === undefined) filter.isArchived = checkedArchive;

    setFilters(filter);

    if (filter.page !== undefined) setPage(filter.page);
    if (filter.size) setRowsPerPage(filter.size);
    return await jwtInterceptor
      .post(`${LAND_ENDPOINTS.searchMyLands}`, filter)
      .then((res) => {
        setTotal(res.data.data.total);
        setDataTable(res.data.data.results);
      })
      .catch((error) => {
        toaster.notifyError(error.message);
      })
      .finally(() => setLoading(false));
  };

  const getData = (perPage, page, favoriteOnly = false, isArchived = false) => {
    searchfn({
      ...filters,
      page,
      size: perPage,
      favoritesOnly: favoriteOnly,
      isArchived: isArchived,
    });
  };

  const getDataFiltered = () => {
    searchfn(searchForm).then(() => setModalShow(false));
  };

  const fontWeightTitle = {
    fontWeight: "600",
  };

  const handleChangeCheckFavorite = () => {
    let favoriteOnly = false;
    if (!checkedFavorite) {
      favoriteOnly = true;
    }
    getData(rowsPerPage, page, favoriteOnly, checkedArchive);
    setCheckedFavorite(!checkedFavorite);
  };

  const handleChangeCheckArchive = () => {
    let checkedArchiveOnly = false;
    if (!checkedArchive) {
      checkedArchiveOnly = true;
    }
    getData(rowsPerPage, page, checkedFavorite, checkedArchiveOnly);
    setCheckedArchive(!checkedArchive);
  };

  return (
    <Box className="my-4 w-100  px-4">
      <Helmet>
        <title>Mes parcelles - Mixdata</title>
      </Helmet>
      <Grid container spacing={2}>
        <Grid
          className="filters shadow-none"
          xl={3}
          lg={3}
          md={4}
          sm={12}
          xs={12}
        >
          <Paper className="shadow-none">
            <LandsFilterCard handleSearch={(filter) => searchfn(filter)} />
          </Paper>
        </Grid>
        <Grid xl={9} lg={9} md={8} sm={12} xs={12}>
          <Box className="mb-3 d-flex justify-content-between">
            <h3 style={{ ...fontWeightTitle }}>Mes parcelles</h3>
            <Nav.Link as={Link} to="/map" state={filters}>
              <CustomBtn
                label="Map"
                bgColor="#299CDB"
                iconBg={"#3ba9e5"}
                icon={<Explore />}
              />
            </Nav.Link>
          </Box>
          <Box className="listparcels h-auto w-100 m-0 position-relative z-index">
            <Paper
              className={
                "py-4 px-4 rounded shadow-none App-container m-0 overflow-hidden position-relative z-index" +
                " " +
                (clickShow ? " d-none" : " d-block")
              }
            >
              <Box>
                <Box class="d-flex align-items-center" sx={{ display: "flex", alignItems: "center" }}>
                  <Typography  className="me-2" sx={{fontSize: "14px"}}>Filtrer par :</Typography>
                  <Box>
                  <FormControlLabel
                    label="Archives"
                    control={
                      <Checkbox
                        checked={checkedArchive}
                        onChange={handleChangeCheckArchive}
                      />
                    }
                  />
                  <FormControlLabel
                    label="Favoris"
                    control={
                      <Checkbox
                        checked={checkedFavorite}
                        onChange={handleChangeCheckFavorite}
                      />
                    }
                  />
                  </Box>
                </Box>
              </Box>
              <Box className="overflow-auto">
                <Table>
                  <thead
                    style={{
                      background: "#eeeeee",
                      borderBottom: "2px #DEE2E6 solid",
                      fontWeight: "normal",
                      color: "#363636",
                      marginBottom: "5px",
                    }}
                  >
                    <tr class="parcelles-table">
                      <th className="text-center">Actions</th>
                      {colums.map((field) => {
                        return (
                          <th align="left" key={field.accessor}>
                            {field.show}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody className="parcelTables">
                    {loading ? (
                      <tr>
                        <td
                          colSpan={colums.length + 2}
                          style={{ width: "1614px" }}
                        >
                          Chargement en cours...
                        </td>
                      </tr>
                    ) : dataTable.length > 0 ? (
                      dataTable.map((row, index) => (
                        <TableRowLand
                          clickOne={(event) => handleOnClickRow(row._source.id)}
                          key={row._source.id}
                          keyIndex={index}
                          row={row}
                          actions={[
                            TABLE_ROW_ACTIONS.favorite,
                            TABLE_ROW_ACTIONS.archive,
                          ]}
                          onChange={onRowChanges}
                        />
                      ))
                    ) : (
                      <tr>
                        <td colSpan={colums.length} style={{ width: "1614px" }}>
                          Aucun résultat trouvé
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Box>
              <TablePagination
                component="div"
                count={total}
                page={!page || page <= 0 ? 0 : page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                labelRowsPerPage="Lignes par page"
                onRowsPerPageChange={handleChangeRowsPerPage}
                backIconButtonProps={{
                  "aria-label": "Précédent",
                  className: "PreviousBtn",
                }}
                nextIconButtonProps={{
                  "aria-label": "Suivant",
                  className: "NextBtn",
                }}
              />
            </Paper>
          </Box>
          <Box class="d-none">
            <MapsLayout />
          </Box>
          <Box>
            <FilterModal
              show={modalShow}
              onHide={() => setModalShow(false)}
              onFilter={(item) => {
                setSearchForm((prev) => ({
                  ...prev,
                  ...item,
                }));
              }}
              onSubmit={getDataFiltered}
            />
          </Box>
        </Grid>
      </Grid>
      <Box className={`resume ${showResume ? "open" : "closed"}`}>
        <ParcelResume closeFenetre={toggleResume} landId={selectedLandId} />
      </Box>
    </Box>
  );
};

export default MyLandContainer;
