import { createContext, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import globalRouter from "../utils/globalRouter"

export const AuthContext = createContext()

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState("")
  const [details, setDetails] = useState(0)
  const memoizedContext = useMemo(() => ({
    user,
    setUser,
    details,
    setDetails
  }), [
    user,
    setUser,
    details,
    setDetails
  ])
  const navigate = useNavigate();
  globalRouter.navigate = navigate;

  return (
    <AuthContext.Provider value={memoizedContext}>
      {children}
    </AuthContext.Provider>
  )
}
