import jwtInterceptor from "./jwtInterceptor";
import {WGS84toLV95} from "./coordinateConverter";
import {searchLocations} from "./geoApiService";
import {GOV_API_ENDPOINTS, LANDS_LINK_ENDPOINTS} from "../utils/api";

class LandsLinkService {

    async saveLandsLink(landsLink) {
        try{
            return jwtInterceptor.post(LANDS_LINK_ENDPOINTS.upsert, landsLink).then(response => {
                if(response?.isError === true && response.data.records.length > 0) {
                    return response?.data?.records.shift()
                }
            })
        }
        catch(error){
            throw new Error(error)
        }
    }

    async getEnsoleillementLinkByLand(land) {
        try {
            let solarPotentialUrl;
            if (!land.geo_center.lat || !land.geo_center.lon) {
                throw new Error("Coordonnées manquantes");
            }

                const result = await searchLocations(
                    land.geo_center.lat,
                    land.geo_center.lon
                );

                if (result && result.featureId) {
                    solarPotentialUrl = GOV_API_ENDPOINTS.solarPotentialUrl.replace(":featureId", result.featureId);
                } else {
                    throw new PotentialEnsoleillementError("Données d'ensoleillement non disponibles");
                }

                return solarPotentialUrl

        }catch(err) {
            throw new PotentialEnsoleillementError("Erreur lors de la récupération des données");
        }

    }

}

export const landsLinkService = new LandsLinkService()

export class PotentialEnsoleillementError extends Error {

}