import React, { useState, useRef, useEffect } from "react";
import { Box, Autocomplete, Button, CircularProgress } from "@mui/material";
import TextField from "@mui/material/TextField";
import { ToastContainer, toast } from "react-toastify";
import InputAdornment from "@mui/material/InputAdornment";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { Language } from "@mui/icons-material";
import SimpleInputFile from "../../inputfile/simpleinputfile";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ContactsData from "../staticapi/contacts.json";
import ListApi from "../../listcrm/staticApi/ListCrm.json";
import jwtInterceptor from "../../../service/jwtInterceptor";
import { CONTACT_ENDPOINTS } from "../../../utils/api";
import { z } from "zod";
import { landService } from "../../../service/crm/LandService";
import {useToast} from "../../../Context/ToastContext";

const CreateContact = ({ closeIt, contact, numParcelle, onContactCreated }) => {
  const [errors, setErrors] = useState({});
  const [file, setFile] = useState([]);
  const [mails, setMails] = useState([{ id: 1, label: `email 1`, value: "" }]);
  const [landsOptions, setLandsOptions] = useState([]);
  

  const [selectedParcelle, setSelectedParcelle] = useState({
    label: "",
    value: null,
  });
  const [_id, set_id] = useState(
    contact && contact._id ? contact._id : undefined
  );
  const [nom, setNom] = useState(
    contact && contact.lastName ? contact.lastName : ""
  );
  const [prenom, setPrenom] = useState(
    contact && contact.firstName ? contact.firstName : ""
  );
  const [habitation, setHabitation] = useState(
    contact && contact.address ? contact.address : ""
  );
  const [numeroFixe, setNumeroFix] = useState(
    contact &&
      contact.phones &&
      contact.phones.filter((phone) => phone.type === "work").length > 0
      ? contact.phones.filter((phone) => phone.type === "work")[0].number
      : ""
  );
  const [numeroMobile, setNumeroMobile] = useState(
    contact &&
      contact.phones &&
      contact.phones.filter((phone) => phone.type === "work").length > 0
      ? contact.phones.filter((phone) => phone.type === "mobile")[0].number
      : ""
  );
  const [siteWeb, setSiteWeb] = useState(
    contact && contact.website ? contact.website : ""
  );
  const [facebook, setFacebook] = useState(
    contact && contact.facebook ? contact.facebook : ""
  );
  const [linkedin, setLinkedin] = useState(
    contact && contact.linkedin ? contact.linkedin : ""
  );
  const [twitter, setTwitter] = useState(
    contact && contact.twitter ? contact.twitter : ""
  );
  const [whatsapp, setWhatsapp] = useState(
    contact && contact.whatsapp
      ? contact.whatsapp.replace("https://wa.me/", "")
      : ""
  );
  const [types, setType] = useState(
    contact && contact.types
      ? { label: contact.types, value: contact.types }
      : ""
  );
  const [isLoading, setIsLoading] = useState(false);
  const toaster = useToast()

  //Toasts
  const champsvide = () =>
    toast.warn("Veuillez compléter les champs obligatoires");
  const successtoast = () => toaster.notifySuccess((contact ? "Contact modifié avec succès" : "Contact créé avec succès"));
  const errortoast = () => toaster.notifySuccess((contact ? "Problème lors de la modification" :  "Problème lors de la création"));

  const initialContacts =
    JSON.parse(localStorage.getItem("ContactsData"))?.personnes || [];
  const [contacts, setContacts] = useState(initialContacts);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 897,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const getPhoneNumbers = () => {
    return [
      {
        number: numeroFixe,
        type: "work",
      },
      {
        number: numeroMobile,
        type: "mobile",
      },
    ];
  };

  const fetchLands = () => {
    console.log("numParcelle", numParcelle);
    if (numParcelle) {
      setSelectedParcelle({
        label: numParcelle,
        value: numParcelle.toString(),
      });
    } else {
      landService.getMyLand().then((lands) => {
        setLandsOptions(
          lands.map((land) => {
            return {
              label: land.resume.code_number
                ? land.resume.code_number.toString() + ` (${land.resume.area}m² - ${land.resume.city_name ? land.resume.city_name : ""} - ${land.resume.region_name ? land.resume.region_name : ""})`
                : "",
              value: land._id.toString(),
            };
          })
        );
      });
    }
  };

  const fetchData = () => {
    fetchLands();
  };
  const setDefaultMail = () => {
    if (contact && contact.emails && contact.emails.length > 0) {
      const emails = [];
      contact.emails.map((email, index) => {
        const rowId = index + 1;
        emails.push({
          id: rowId,
          label: `email ${rowId}`,
          value: email,
        });
      });
      setMails(emails);
    }
  };
  useEffect(() => {
    setDefaultMail();
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const UrlSchema = z
    .string()
    .regex(/^https:\/\/.+/, { message: "L'URL doit commencer par 'https://'" })
    .optional()
    .or(z.string().length(0));
  const WhatsappUrlSchema = z
    .string()
    .regex(/^\d{7,15}$/, { message: "Numéro de téléphone invalide" })
    .optional()
    .or(z.string().length(0));
  const EmailSchema = z
    .string()
    .regex(/^(?:[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}|)$/)
    .optional();
  const contactSchema = z.object({
    lastName: z
      .string()
      .max(50, { message: "50 caractères max" })
      .nonempty({ message: "Champ obligatoire" }),
    firstName: z
      .string()
      .max(50, { message: "50 caractères max" })
      .nonempty({ message: "Champ obligatoire" }),
    address: z.string().max(250, { message: "250 caractères max" })
    .nonempty({ message: "Champ obligatoire" }),
    fix_phone: z
      .string()
      .regex(
        /^$|(\+\d{1,3}\s?)?((\(\d{3}\)\s?)|(\d{3})(\s|-?))(\d{3}(\s|-?))(\d{4})(\s?(([E|e]xt[:|.|]?)|x|X)(\s?\d+))?/,
        { message: "Numéro de téléphone invalide" }
      )
      .optional(),
    mobile_phone: z
      .string()
      .regex(
        /^$|(\+\d{1,3}\s?)?((\(\d{3}\)\s?)|(\d{3})(\s|-?))(\d{3}(\s|-?))(\d{4})(\s?(([E|e]xt[:|.|]?)|x|X)(\s?\d+))?/,
        { message: "Numéro de téléphone invalide" }
      )
      .optional(),
    website: UrlSchema,
    facebook: UrlSchema,
    linkedin: UrlSchema,
    twitter: UrlSchema,
    whatsapp: WhatsappUrlSchema,
    types: z.string().nonempty({ message: "Champ obligatoire" }),
    emails: z.array(z.string()).refine(
      (emails) => {
        return emails.every((email) => EmailSchema.safeParse(email).success);
      },
      { message: "Un ou plusieur email sont invalides" }
    ),
    selectedParcelle: z.object({
      label: z.string().nonempty({ message: "Champ obligatoire" }),
      value: z.string().nonempty({ message: "Champ obligatoire" }),
    })
  });

  const verifyContactData = async (contactData) => {
    try {
      const fix_phoneObject = contactData.phones.filter(
        (phone) => phone.type === "work"
      )[0];
      const mobile_phoneObject = contactData.phones.filter(
        (phone) => phone.type === "mobile"
      )[0];
      const verifiableContact = {
        ...contactData,
        fix_phone: fix_phoneObject ? fix_phoneObject.number : "",
        mobile_phone: mobile_phoneObject ? mobile_phoneObject.number : "",
      };
      console.log("verifiableContact", verifiableContact);
      contactSchema.parse(verifiableContact);
    } catch (error) {
      if (error instanceof z.ZodError) {
        const validationErrors = {};
        error.errors.forEach((err) => {
          const fieldName = err.path[0];
          validationErrors[fieldName] = err.message;
        });
        await setErrors(validationErrors);
        throw error;
      } else {
        throw error;
      }
    }
  };

  const handleAddContact = async () => {
    setIsLoading(true);
    try {
      console.log("Début de la création du contact");
    const contactRequest = buildContactRequest();
    console.log("Contact request:", contactRequest);
    await verifyContactData(contactRequest);
    console.log("Données de contact vérifiées");

      const contactWithWhatsApp = {
        ...contactRequest,
        whatsapp: contactRequest.whatsapp
          ? `https://wa.me/${contactRequest.whatsapp}`
          : "",
      };
      console.log("Contact avec WhatsApp:", contactWithWhatsApp);

      const newContactResponse = await jwtInterceptor.post(
        CONTACT_ENDPOINTS.new,
        contactWithWhatsApp
      );

      const newContact = newContactResponse.data.data.records[0];

      console.log("Réponse du serveur:", newContactResponse);

      updateContactLocalStorage(newContact); 
      if (typeof onContactCreated === 'function') {
        onContactCreated(newContact);
      } else {
        console.error("onContactCreated is not a function");
      }

      successtoast();
      closeIt();
    } catch (error) {
      console.error("Erreur détaillée:", error);
      console.log("error", error);
      errortoast();
    }
    setIsLoading(false);
  };

  const getValidateButtonLabel = () => {
    return contact ? "Modifier" : "Ajouter";
  };

  const buildContactRequest = () => {
    const phone_numbers = getPhoneNumbers();
    const landsAttribute = [
      {
        _id: selectedParcelle.value,
      },
    ];
    const emailsData = mails.map((email) => email.value);
    console.log("emails", emailsData);
    console.log("types", types);
    const selectedParcelleData = selectedParcelle.value
    ? {
      label: selectedParcelle.label,
      value: selectedParcelle.value,
    }
    : undefined;

    return {
      _id: _id,
      lastName: nom,
      firstName: prenom,
      address: habitation,
      phones: phone_numbers,
      website: siteWeb,
      facebook,
      linkedin,
      twitter,
      whatsapp,
      types: types,
      lands: landsAttribute,
      emails: emailsData,
      selectedParcelle: selectedParcelleData,
    };
  };

  const updateContactLocalStorage = (newContact) => {
    const existingContacts =
      JSON.parse(localStorage.getItem("ContactsData"))?.personnes || [];

    const listContact = {
      nom,
      prenom,
      type: newContact.types,
    };

    setContacts(
      (prevContacts) => [...prevContacts, newContact],
      (prevListContacts) => [...prevListContacts, listContact]
    );

    const updatedData = {
      personnes: [...existingContacts, newContact],
    };
    localStorage.setItem("ContactsData", JSON.stringify(updatedData));

    setTimeout(() => {
      closeIt();
    }, 1000);
  };

  const handleAddEmail = () => {
    setMails((prevMails) => [
      ...prevMails,
      {
        id: prevMails.length + 1,
        label: `email ${prevMails.length + 1}`,
        value: "",
      },
    ]);
  };

  const handleOnChangeEmail = (id, value) => {
    const newMails = mails.map((email) => {
      if (email.id === id) {
        return { ...email, value: value };
      } else {
        return email;
      }
    });
    setMails(newMails);
  };

  const handleUpload = (files) => {
    setFile(files);
  };
  const type = [
    {
      label: "Propriétaire",
      value: "Propriétaire",
    },
    {
      label: "Prospect",
      value: "Prospect",
    },
  ];
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Box sx={style}>
        <h4 style={{ marginBottom: 20 }}>Création de contact</h4>
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { my: 1, maxWidth: "400px" },
          }}
          noValidate
          autoComplete="off"
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <TextField
              id="nom"
              label="nom *"
              fullWidth
              value={nom}
              onChange={(e) => setNom(e.target.value)}
            />

            <TextField
              required
              id="prenom"
              label="prénom"
              fullWidth
              value={prenom}
              onChange={(e) => setPrenom(e.target.value)}
            />
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            {errors.lastName ? (
              <p style={{ color: "red" }}>{errors.lastName}</p>
            ) : (
              <></>
            )}
            {errors.firstName ? (
              <p style={{ color: "red" }}>{errors.firstName}</p>
            ) : (<></>)}
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <TextField
              id="numero_fixe"
              label="fixe"
              fullWidth
              value={numeroFixe}
              onChange={(e) => setNumeroFix(e.target.value)}
            />

            <TextField
              id="numero_mobile"
              label="mobile"
              fullWidth
              value={numeroMobile}
              onChange={(e) => setNumeroMobile(e.target.value)}
            />
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            {errors.fix_phone ? (
              <p style={{ color: "red" }}>{errors.fix_phone}</p>
            ) : (
              <></>
            )}
            {errors.mobile_phone && (
              <p style={{ color: "red" }}>{errors.mobile_phone}</p>
            )}
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <TextField
              id="habitation"
              label="Adresse *"
              fullWidth
              value={habitation}
              onChange={(e) => setHabitation(e.target.value)}
            />

            <Autocomplete
              disablePortal
              id="type"
              sx={{ width: "50%", display: "flex", justifyContent: "flex-end" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  sx={{ width: "100%" }}
                  label="type de contact"
                />
              )}
              fullWidth
              options={type}
              onInputChange={(event, newInputValue) => {
                setType(newInputValue);
              }}
              value={types}
            />
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            {errors.address ? (
              <p style={{ color: "red" }}>{errors.address}</p>
            ) : (
              <></>
            )}
            {errors.types ? (<p style={{ color: "red" }}>{errors.types}</p>) : <></>}
          </Box>

          {!numParcelle ? (
            <Box
              sx={{
                justifyContent: "space-between",
                "& .MuiTextField-root": { maxWidth: "100%" },
              }}
            >
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                sx={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Parcelle n° *"
                    value={selectedParcelle.label}
                  />
                )}
                fullWidth
                options={landsOptions}
                onChange={(event, newValue) => {
                  setSelectedParcelle(
                    newValue ? newValue : { label: "", id: null }
                  );
                }}
                isOptionEqualToValue={(option, value) => option.value === value}
              />
              <Box class="w-100">
              {errors.selectedParcelle && <p style={{ color: "red" }}>Champ obligatoire</p>}
              </Box>
            </Box>
          ) : (
            ""
          )}

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            {mails.map((email) => (
              <TextField
                key={email.id}
                id={`${email.id}`}
                label={email.label}
                value={email.value}
                onChange={(e) => handleOnChangeEmail(email.id, e.target.value)}
                fullWidth
              />
            ))}
            <AddCircleIcon
              sx={{ color: "#299CDB", width: "50%" }}
              onClick={handleAddEmail}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            {errors.emails && <p style={{ color: "red" }}>{errors.emails}</p>}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <Box style={{ maxWidth: "400px", width: "100%", my: 1 }}>
              <TextField
                label="facebook"
                id="facebook"
                sx={{ width: "100%" }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <FacebookOutlinedIcon sx={{ color: "#1196f5" }} />
                    </InputAdornment>
                  ),
                }}
                value={facebook}
                onChange={(e) => setFacebook(e.target.value)}
              />
              {errors.facebook && (
                <p style={{ color: "red" }}>{errors.facebook}</p>
              )}
            </Box>

            <Box style={{ maxWidth: "400px", width: "100%", my: 1 }}>
              <TextField
                label="linkedin"
                id="linkedin"
                sx={{ width: "100%" }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <LinkedInIcon sx={{ color: "#0a66c2" }} />
                    </InputAdornment>
                  ),
                }}
                value={linkedin}
                onChange={(e) => setLinkedin(e.target.value)}
              />
              {errors.linkedin && (
                <p style={{ color: "red" }}>{errors.linkedin}</p>
              )}
            </Box>
            <Box style={{ maxWidth: "400px", width: "100%", my: 1 }}>
              <TextField
                label="twitter"
                id="twitter"
                sx={{ width: "100%" }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <TwitterIcon sx={{ color: "#1d9bf0" }} />
                    </InputAdornment>
                  ),
                }}
                value={twitter}
                onChange={(e) => setTwitter(e.target.value)}
              />
              {errors.twitter && (
                <p style={{ color: "red" }}>{errors.twitter}</p>
              )}
            </Box>
            <Box style={{ maxWidth: "400px", width: "100%", my: 1 }}>
              <TextField
                label="whatsapp"
                id="whatsapp"
                sx={{ width: "100%" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      https://wa.me/
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <WhatsAppIcon sx={{ color: "#25d366" }} />
                    </InputAdornment>
                  ),
                }}
                value={whatsapp}
                onChange={(e) => setWhatsapp(e.target.value)}
              />
              {errors.whatsapp && (
                <p style={{ color: "red" }}>{errors.whatsapp}</p>
              )}
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {/* <SimpleInputFile handleUpload={handleUpload} /> */}
            <div style={{ width: "50%" }}>
              <TextField
                id="site_web"
                label="site web"
                fullWidth
                sx={{ width: "100%" }}
                value={siteWeb}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Language sx={{ color: "#0A66C2" }} />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => setSiteWeb(e.target.value)}
              />
              {errors.website && (
                <p style={{ color: "red" }}>{errors.website}</p>
              )}
            </div>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end", pt: 2 }}>
            <Button
              sx={{
                background: "#757575",
                color: "#fff",
                "&:hover": { background: "#757575" },
                mr: 2,
              }}
              onClick={closeIt}
            >
              Annuler
            </Button>
            {isLoading ? (
              <CircularProgress color="secondary" />
            ) : (
              <Button
                sx={{
                  background: "#299CDB",
                  color: "#fff",
                  "&:hover": { background: "#299CDB" },
                }}
                onClick={handleAddContact}
              >
                {getValidateButtonLabel()}
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default CreateContact;
