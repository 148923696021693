import { createContext, useContext, useState } from "react";

const TransactionsAndFaosContext = createContext()

export const useTransactionsAndFaos = () => useContext(TransactionsAndFaosContext)

export const TransactionsAndFaosProvider = ({children}) => {
    const [transactions, setTransactions] = useState([])
    const [faos, setFaos] = useState([])


    return (
        <TransactionsAndFaosContext.Provider value={{
            transactions,
            setTransactions,
            faos,
            setFaos
        }}>
            {children}
        </TransactionsAndFaosContext.Provider>
    )
}