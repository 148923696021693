import jwtInterceptor from "./jwtInterceptor";
import proj4 from "proj4";

const API_BASE_URL = 'https://api3.geo.admin.ch/rest/services/api/MapServer/identify';

// Définir les projections
const wgs84 = "EPSG:4326";
const lv95 = "EPSG:2056";

// Ajouter la définition de la projection LV95 (CH1903+/LV95)
proj4.defs(
  lv95,
  "+proj=somerc +lat_0=46.95240555555556 +lon_0=7.439583333333333 +k=1 +x_0=2600000 +y_0=1200000 +ellps=bessel +datum=ch1903 +units=m +no_defs"
);

export const searchLocations = async (lat, lon, width = 1000, height = 1000, dpi = 96) => {
  try {
    // Convertir les coordonnées WGS84 en LV95 en utilisant proj4
    const [x, y] = proj4(wgs84, lv95, [lon, lat]);

    const geometry = `${x},${y}`;

    console.log("Coordonnées converties:", { x, y });

    const response = await jwtInterceptor.get(API_BASE_URL, {
      params: {
        geometry: geometry,
        geometryType: 'esriGeometryPoint',
        layers: 'all:ch.bfe.solarenergie-eignung-daecher',
        tolerance: 10,
        sr: 2056,
        returnGeometry: true,
        lang: 'fr',
        mapExtent: `${x-1000},${y-1000},${x+1000},${y+1000}`,
        imageDisplay: `${width},${height},${dpi}`,
      },
    });

    console.log("Réponse complète de l'API:", response.data);
    return response.data.results[0];
  } catch (error) {
    console.error('Erreur détaillée:', error.response ? error.response.data : error);
    throw error;
  }
};