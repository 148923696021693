import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Box,
  Paper,
  Typography,
  Badge,
  Link,
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  CircularProgress,
  Chip, Skeleton,
} from "@mui/material";
import Image from "react-bootstrap/Image";
import Grid from "@mui/system/Unstable_Grid/Grid";

/****import styles & icons */
import { detailparcelleStyles } from "../detailparcelle/components/styles";
import enqueteIcon from "../../assets/svg/ListParcelle/enqtIcon.svg";
import BiotechOutlinedIcon from "@mui/icons-material/BiotechOutlined";
import localChIcon from "../../assets/Images/logo/logo-localch.png";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { landService } from "../../service/LandService";
import { landService as crmLandService } from "../../service/crm/LandService";
import { noteService } from "../../service/NoteService";
import { contactService } from "../../service/crm/ContactService";
import { useNavigate, useParams } from "react-router-dom";
import { useApplication } from "../../hooks/UseApplication";
import { useToast } from "../../Context/ToastContext";
import zipChIcon from "../../assets/Images/logo/zip_logo_whtie_squared.jpg";
import linkedinIcon from "../../assets/Images/logo/linkedin.png";
import jwtInterceptor from "../../service/jwtInterceptor";
import { API_URL } from "../../utils/constants";
import { searchLocations } from "../../service/geoApiService";
import { WGS84toLV95 } from "../../service/coordinateConverter";
import DownloadIcon from "@mui/icons-material/Download";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CustomBtn from "../customBtns/CustomBtn";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import StarOutlined from "@mui/icons-material/StarOutlined";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import { ChangeFavoriteDialog } from "../FavoriteBox";
import CreateContact from "../detailcrm/modals/creationcontact";
import Modal from "@mui/material/Modal";
import { LAND_ENDPOINTS } from "../../utils/api";
import CreateNoteDynamic from "../CreateNoteDynamic";
import {landsLinkService} from "../../service/LandsLinkService";

/**** style ****/
const brown = "#363636";
const paddingInlineNone = {
  paddingInline: "0px",
};

const contactTdStyle = {
  maxWidth: "200px",
  paddingRight: "10px!important",
};

const ParcelResume = ({ closeFenetre, landId }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [detailParcelle, setDetailParcelle] = useState({});
  const [modifiedGeoPortaLink, setModifiedGeoPortaLink] = useState("");
  const { details, setDetails } = useApplication();
  const [openFavoriteDialog, setOpenFavoriteDialog] = useState(false);
  const [isLandsLoading, setIsLandsLoading] = useState(true);
  const [isFaosLoading, setIsFaosLoading] = useState(true);
  const [isTransactionsLoading, setIsTransactionsLoading] = useState(true);
  const [isContactsLoading, setIsContactsLoading] = useState(true);
  const [isManageLoading, setIsManageLoading] = useState(true);
  const [isSolarPotentialLoading, setIsSolarPotentialLoading] = useState(true);
  const navigate = useNavigate();
  const [notes, setNotes] = useState([]);
  const [crmLandId, setCrmLandId] = useState(false);
  const [contactsList, setContactsList] = useState([]);
  const [crmContacts, setCrmContacts] = useState([]);
  const [elasticContacts, setElasticCrmContacts] = useState([]);
  const [nbrTransactions, setNbrTransactions] = useState(0);
  const [nbrEnquetes, setNbrEnquetes] = useState(0);
  const [solarPotentialUrl, setSolarPotentialUrl] = useState("");
  const [openCreateContact, setOpenCreateContact] = useState(false);
  const [changeOnChild, setChangeOnChild] = useState(false);
  const [createNoteOpen, setCreateNoteOpen] = useState(false)

  const wgs84 = "EPSG:4326";
  const lv95 = "EPSG:2056";

  const [mn95X, setMn95X] = useState(null);
  const [mn95Y, setMn95Y] = useState(null);
  const [batiments, setBatiments] = useState([]);

  const toaster = useToast();

  const handleOnClickDetails = () => {
    window.open(`/details/${detailParcelle.id}`, "_blank");
  };

  useEffect(() => {
    if (landId) {
      fetchDetails()
          .then(response => console.log('finished fetchDetails()'))
        .catch((error) => toaster.notifyError(error.message))
    }
  }, [landId]);

  async function fetchAllContacts(detailById) {
    setIsContactsLoading(true)

    const newElasticContact = await addElasticContactToContactList(
        detailById._source
    );
    const newCRMContact = await fetchContacts(detailById._source.id);
    const newCOntactList = newElasticContact.concat(newCRMContact);
    setContactsList(newCOntactList);

    setIsContactsLoading(false)
  }

  async function fetchDetails() {
    console.log("inside inside inside")
    setIsLandsLoading(true)
    const detailById = await landService
      .findLandById(landId)
      .catch((error) => toaster.notifyError(error.message))
      .finally(() => setIsLandsLoading(false));

    if (!detailById) {
      throw new Error("impossible de trouver la parcelle");
    }

    setDetails(detailById._source);
    setDetailParcelle(detailById._source);

    fetchAllContacts(detailById).then(result => console.log('all contact fetched'))

    console.log("detailById._source.landsLink?.ensoleillementLink", detailById._source.landsLink?.ensoleillementLink)
    if(!(detailById._source.landsLink?.ensoleillementLink)) {
      fetchSolarPotential(detailById)
          .then(response => {
            console.log('fetch solarPotential done')
          })
          .catch(error => toaster.notifyError(error.message))
    }else setSolarPotentialUrl(detailById._source.landsLink.ensoleillementLink)

    if (detailById._source.geo_portal_link) {
      setModifiedGeoPortaLink(
        detailById._source.geo_portal_link
          .replace("{x}", Math.round(mn95X))
          .replace("{y}", Math.round(mn95Y))
      );
    }

    await fetchNotes(detailById._source.land_id);
    await fetchTransactions(detailById._source.land_id);
    await fetchEnquetes(detailById._source.land_id);

    setIsManageLoading(true)
    crmLandService.isManaged(detailById._source.land_id)
        .then((response) => {
            setCrmLandId(response);
          })
        .finally(() => setIsManageLoading(false));

  }

  async function fetchNotes(landId) {
    const notesData = await noteService.findNoteByLandId(landId);
    setNotes(notesData);
  }

  const fetchContacts = async (externalLandId) => {
    const contacts = await contactService.findByExternalLandId(externalLandId);
    let newContactsList = [];

    newContactsList = contacts.map((contact) => {
      return {
        name: contact.firstName,
        type: contact.types,
        byCRM: true,
        link: "/detail/:id/:contactId"
          .replace(":id", contact.lands[0]._id)
          .replace(":contactId", contact._id),
      };
    });

    console.log("newCOntactList", newContactsList);

    setCrmContacts(newContactsList);

    return newContactsList;
  };

  const handleCloseFavoriteModal = () => {
    fetchDetails();
    setOpenFavoriteDialog(false);
  };

  const manageLand = async () => {
    setIsManageLoading(true);
    const manageErrorMessage =
      "Erreur durant la gestion de la parcelle : :errorMessage";
    try {
      const response = await jwtInterceptor.post(
        LAND_ENDPOINTS.manage.replace(":landId", detailParcelle.id)
      );
      if (response.status.toString().startsWith("2")) {
        toaster.notifySuccess("Succès : Vous pouvez la consulter dans le CRM.");
        setCrmLandId(1); // Mettre à jour l'état pour changer le bouton
      } else {
        toaster.notifyError(
          manageErrorMessage.replace(":errorMessage", response.data.message)
        );
      }
      fetchDetails();
    } catch (error) {
      toaster.notifyError(
        manageErrorMessage.replace(":errorMessage", error.message)
      );
    } finally {
      setIsManageLoading(false);
    }
  };

  const handleCloseCreateContact = () => {
    setOpenCreateContact(false);
  };
  const handleOpenCreateContact = () => {
    setOpenCreateContact(true);
  };

  const handleContactCreated = async (newContact) => {
    // Mettre à jour la liste des contacts ici
    await fetchContacts(detailParcelle.id);
    setChangeOnChild(!changeOnChild); // Déclencher une re-rendu si nécessaire
  };

  const fetchSolarPotential = async (landWithSource) => {
    const landSource = landWithSource._source
    setIsSolarPotentialLoading(true)

    const foundedSolarPotentialUrl = await landsLinkService.getEnsoleillementLinkByLand(landSource)
    setSolarPotentialUrl(foundedSolarPotentialUrl)

    setIsSolarPotentialLoading(false)

    let newLandsLinks
    if(landSource.landsLinks) {
      newLandsLinks = {...landSource.landsLinks}
    }else newLandsLinks = {land_id: landSource.land_id}

    newLandsLinks.ensoleillementLink = foundedSolarPotentialUrl
    landsLinkService.saveLandsLink(newLandsLinks).then(newLandsLinks => {
      setDetails({...details, landsLinks : newLandsLinks});
    })
  }

  async function addElasticContactToContactList(rowWithoutSource) {
    let newContactsList = [];
    console.log("inside addElastic");

    if (rowWithoutSource.owners && rowWithoutSource.owners.length > 0) {
      newContactsList = rowWithoutSource.owners.map((owner) => {
        return {
          name: owner.name,
          type: owner.type === "private" ? "privé" : "public",
          byCRM: false,
          link: "#",
        };
      });
    }

    console.log("elasticCOntact", newContactsList);
    setElasticCrmContacts(newContactsList);
    return newContactsList;
  }

  const formatSearchUrl = (baseUrl, query) => {
    return `${baseUrl}${encodeURIComponent(query)}`;
  };

  const fetchTransactions = async (parcelleId) => {
    setIsTransactionsLoading(true)
    const response = await jwtInterceptor.get(
      `${API_URL}/transaction/land/${parcelleId}`,
      {
        size: 2000,
        page: 1,
        region: "all",
      }
    );
    const data = response.data.data.results;
    const matchingTransactions = await data.filter(
      (transac) => transac._source.lands_id === parcelleId.toString()
    );
    setNbrTransactions(matchingTransactions.length);

    setIsTransactionsLoading(false)
  };

  const fetchEnquetes = async (parcelleId) => {
    setIsFaosLoading(true)
    const response = await jwtInterceptor.get(
      `${API_URL}/faos/land/${parcelleId}`,
      {
        size: 2000,
        page: 1,
        region: "all",
      }
    );

    const data = response.data.data.results;
    const matchingEnquetes = await data.filter(
      (enq) => enq._source.lands_id === parcelleId.toString()
    );
    setNbrEnquetes(matchingEnquetes.length);
    setIsFaosLoading(false)

  };

  const getStarIcon = () => {
    return details.landsInteractions &&
      details.landsInteractions.isFavorites ? (
      <StarOutlined sx={{ fill: "#363636", height: "17px" }} />
    ) : (
      <StarBorderIcon sx={{ fill: "#fff", height: "17px" }} />
    );
  };

  useEffect(() => {}, [changeOnChild]);

  return (
    <Paper className="inner p-3">
      <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 3,
              flexWrap: "wrap ",
            }}
          >
            <Box
              sx={{
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
                display: "flex"
              }}
            >
              <CloseIcon
                onClick={closeFenetre}
                sx={{ cursor: "pointer", display: "block" }}
              />
              <Typography sx={{color: "brown"  }}>
                <span style={{fontWeight: "bold"}}>{details?.landsInteractions?.isArchived ? "Archivée" : ""}</span>
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                marginBottom: "8px",
              }}
            >
              <Box onClick={handleOnClickDetails} sx={{ cursor: "pointer" }}>
                {isLandsLoading ? (<Skeleton variant="rounded" animation="wave" width="300px" />) :
                <Typography
                  variant="h4"
                  className=""
                  sx={{
                    ...detailparcelleStyles.fontWeightTitle,
                    mr: 1,
                    fontSize: "1.625rem",
                    textDecoration: "underline",
                    color: "#3F50B6",

                  }}
                >
                  Parcelle n°{detailParcelle.code_number}
                </Typography>
                }
              </Box>
              <Box sx={{ minWidth: "100px" }}>
                <Box
                  className="d-flex  justify-content-evenly"
 
                >
                  {isFaosLoading ? (<Skeleton variant="rounded" animation="wave" className="px-4" />) :
                      <Badge
                          class="px-4"
                          badgeContent={
                            nbrEnquetes > 0 ? nbrEnquetes.toString() : "0"
                          }
                          color="primary"
                          size="small"
                          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                      >
                        <BiotechOutlinedIcon style={{ color: brown }} />
                      </Badge>
                  }

                  {isTransactionsLoading ? (<Skeleton variant="rounded" animation="wave" className="px-4" />) :
                  <Badge
                    class="px-4"
                    badgeContent={
                      nbrTransactions > 0 ? nbrTransactions.toString() : "0"
                    }
                    color="primary"
                    size="small"
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  >
                    <MonetizationOnOutlinedIcon style={{ color: brown }} />
                  </Badge>
                  }
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexWrap: {
                  xl: "nowrap",
                  lg: "nowrap",
                  md: "nowrap",
                  sm: "wrap",
                  xs: "wrap",
                },
              }}
            >
              {isLandsLoading ? (<Skeleton variant="rounded" animation="wave" width="100%" />) :
              <>
              <CustomBtn
                bgColor={
                  details.landsInteractions &&
                  details.landsInteractions.isFavorites
                    ? "#FFC107"
                    : "#363636"
                }
                label="Favoris"
                iconBg={
                  details.landsInteractions &&
                  details.landsInteractions.isFavorites
                    ? "#FFC107"
                    : "#363636"
                }
                icon={getStarIcon()}
                textColor={
                  details.landsInteractions &&
                  details.landsInteractions.isFavorites
                    ? "#363636"
                    : "#fff"
                }
                click={() => setOpenFavoriteDialog(true)}
                mR={"15px"}
              />

              <CustomBtn
                  click={() => setCreateNoteOpen(true)}
                bgColor={"rgb(41, 156, 219)"}
                label="Note"
                iconBg={"rgb(41, 156, 219)"}
                icon={"+"}
                mR={"15px"}
              />

              {isManageLoading ? (
                <Skeleton variant="text" animation="wave" />
              ) : crmLandId === 0 ? (
                <Box
                  sx={{
                    marginInline: { xl: 0, lg: 0, md: 0, sm: 0, xs: 1 },
                  }}
                >
                  <CustomBtn
                    bgColor={"#3F50B6"}
                    label="Gérer"
                    iconBg={"#3F50B6"}
                    icon={"+"}
                    click={manageLand}
                  />
                </Box>
              ) : (
                <Box
                  sx={{
                    marginInline: { xl: 0, lg: 0, md: 0, sm: 0, xs: 1 },
                  }}
                >
                  <CustomBtn
                    bgColor={"#3F50B6"}
                    label="Contact"
                    iconBg={"#3F50B6"}
                    icon={"+"}
                    click={handleOpenCreateContact}
                  />
                </Box>
              )}
              </>
              }
            </Box>
          </Box>
          <Box sx={{ mb: 3 }}>
            {isLandsLoading ? (<Skeleton variant="rounded" animation="wave" className="p-3" height="300px" />) :
            <Box className="p-3" sx={detailparcelleStyles.grayBg}>
              {detailParcelle.region_name && (
                <p>
                  <span
                    style={{
                      color: detailparcelleStyles.graylight,
                      ...detailparcelleStyles.fontWeightTitle,
                    }}
                  >
                    Canton :
                  </span>{" "}
                  {detailParcelle.region_name}
                </p>
              )}

              {detailParcelle.address_full && (
                <p>
                  <span
                    style={{
                      color: detailparcelleStyles.graylight,
                      ...detailparcelleStyles.fontWeightTitle,
                    }}
                  >
                    Adresse :
                  </span>{" "}
                  {detailParcelle.address_full}
                </p>
              )}
              {detailParcelle.average_building_year && (
                <p>
                  <span
                    style={{
                      color: detailparcelleStyles.graylight,
                      ...detailparcelleStyles.fontWeightTitle,
                    }}
                  >
                    Année de construction :
                  </span>{" "}
                  {detailParcelle.average_building_year}
                </p>
              )}
              {detailParcelle.area && (
                <p>
                  <span
                    style={{
                      color: detailparcelleStyles.graylight,
                      ...detailparcelleStyles.fontWeightTitle,
                    }}
                  >
                    Surface :
                  </span>{" "}
                  {detailParcelle.area} m<sup>2</sup>
                </p>
              )}
              {detailParcelle.main_lupa_name && (
                <p style={{ mb: 0 }}>
                  <span
                    style={{
                      color: detailparcelleStyles.graylight,
                      ...detailparcelleStyles.fontWeightTitle,
                    }}
                  >
                    Zone d'affectation :
                  </span>{" "}
                  {detailParcelle.main_lupa_name}
                </p>
              )}
            </Box>
            }
          </Box>
          <Grid container columnSpacing={1} rowSpacing={1} sx={{ mb: 3 }}>
            <Grid lg={6}>
              {isLandsLoading ? (<Skeleton variant="rounded" animation="wave" width="100px" />) :
              <Link
                href={detailParcelle.rf_uri}
                target="_blank"
                rel="noreferrer"
                sx={{ display: "flex", justifyContent: "flex-start" }}
              >
                <CustomBtn
                  bgColor={"rgb(41, 156, 219)"}
                  label="Registre Foncier"
                  iconBg={"rgb(41, 156, 219)"}
                  icon={<OpenInNewIcon sx={{ fontSize: "18px" }} />}
                  // fontSizes="12px"
                  noShadow
                  fullWidth
                />
              </Link>
              }
            </Grid>
            <Grid lg={6}>
              {isLandsLoading ? (<Skeleton variant="rounded" animation="wave" width="100px" />) :
                <Link
                href={modifiedGeoPortaLink}
              target="_blank"
              rel="noreferrer"
              sx={{display: "flex", justifyContent: "flex-start"}}
            >
              <CustomBtn
                  bgColor={"#3F50B6"}
                  label="Géoportail"
                  iconBg={"#3F50B6"}
                  icon={<OpenInNewIcon sx={{fontSize: "18px"}}/>}
                  // fontSizes="12px"
                  noShadow
                  fullWidth
              />
            </Link>
            }
            </Grid>

            <Grid lg={6}>
              {isLandsLoading ? (<Skeleton variant="rounded" animation="wave" width="100px" />) :
                  <Link
                href={detailParcelle.cadastreLink}
                target="_blank"
                rel="noreferrer"
                sx={{ display: "flex", justifyContent: "flex-start" }}
              >
                <CustomBtn
                  bgColor={"#3F86B6"}
                  label="Cadastre"
                  iconBg={"#3F86B6"}
                  icon={<OpenInNewIcon sx={{ fontSize: "18px" }} />}
                  // fontSizes="12px"
                  noShadow
                  fullWidth
                />
              </Link>
              }
            </Grid>

            {(isLandsLoading && isSolarPotentialLoading) ? (
                <Skeleton animation="wave" variant="text" ><Grid lg={6}></Grid></Skeleton>
            ) : !solarPotentialUrl ? (
              <></>
            ) : (
              <Grid lg={6}>
                <Link
                  href={solarPotentialUrl}
                  target="_blank"
                  rel="noreferrer"
                  sx={{ display: "flex", justifyContent: "flex-start" }}
                >
                  <CustomBtn
                    bgColor={"#6E3FB6"}
                    label="Ensoleillement"
                    iconBg={"#6E3FB6"}
                    icon={<OpenInNewIcon sx={{ fontSize: "18px" }} />}
                    // fontSizes="12px"
                    noShadow
                    fullWidth
                  />
                </Link>
              </Grid>
            )
            }
          </Grid>
          <Box>
            <Box className="d-flex justify-content-between align-items-center">
              <Typography
                variant="h5"
                component="h5"
                style={detailparcelleStyles.fontWeightTitle}
              >
                {isContactsLoading ? <Skeleton /> : 'Contacts'}
              </Typography>
            </Box>
            <Box className="overflow-auto" sx={{ maxHeight: "350.27px" }}>
              {isContactsLoading ? <Skeleton variant="rounded" animation="wave" height="100%" width="100%"/> :
                  <Table size="small" aria-label="purchases">
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ ...contactTdStyle, ...paddingInlineNone }}>
                          Nom
                        </TableCell>
                        <TableCell sx={paddingInlineNone}>Type</TableCell>
                        <TableCell sx={paddingInlineNone}>Rechercher</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody style={{ overflow: "auto" }}>
                      {contactsList && contactsList.length > 0 ? (
                          contactsList.map((item) => {
                            const query = item.name || item.crm;
                            const searchChUrl = formatSearchUrl(
                                "https://search.ch/?q=",
                                query
                            );
                            const zipChUrl = formatSearchUrl(
                                "https://zip.ch/fr/results/?q=",
                                query
                            );
                            const linkedInUrl = formatSearchUrl(
                                "https://www.linkedin.com/search/results/all/?keywords=",
                                query
                            );

                            return (
                                <TableRow
                                    key={item.name}
                                    onClick={() => navigate(item.link)}
                                    sx={
                                      item.byCRM
                                          ? { cursor: "pointer" }
                                          : { cursor: "inherit" }
                                    }
                                >
                                  <TableCell
                                      component="th"
                                      scope="row"
                                      sx={{ ...contactTdStyle, ...paddingInlineNone }}
                                  >
                                    {item.name}{" "}
                                  </TableCell>
                                  <TableCell
                                      component="th"
                                      scope="row"
                                      sx={paddingInlineNone}
                                  >
                                    {item.type}
                                  </TableCell>
                                  <TableCell
                                      component="th"
                                      scope="row"
                                      sx={paddingInlineNone}
                                  >
                                    {item.byCRM ? (
                                        <>
                                          <Chip sx={{ height: "20px" }} label="CRM" />
                                        </>
                                    ) : (
                                        <>
                                          <a
                                              href={searchChUrl}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                          >
                                            <img
                                                height="20"
                                                alt="search.ch"
                                                src="https://lib.search.ch/favicon.ico?c=3"
                                            />
                                          </a>
                                          <a
                                              href={zipChUrl}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              style={{ marginInline: "5px" }}
                                          >
                                            <img
                                                height="20"
                                                alt="zip.ch"
                                                src={zipChIcon}
                                                style={{ objectFit: "contain" }}
                                            />
                                          </a>
                                          <a
                                              href={linkedInUrl}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                          >
                                            <img
                                                height="21"
                                                alt="linkedin"
                                                src={linkedinIcon}
                                                style={{ objectFit: "contain" }}
                                            />
                                          </a>
                                        </>
                                    )}
                                  </TableCell>
                                </TableRow>
                            );
                          })
                      ) : (
                          <TableRow>
                            <TableCell colSpan={4}>Aucun contact trouvé</TableCell>
                          </TableRow>
                      )}
                    </TableBody>
                  </Table>

              }
            </Box>
          </Box>
        </>
      <ChangeFavoriteDialog
        row={detailParcelle}
        open={openFavoriteDialog}
        onClose={handleCloseFavoriteModal}
      />
      <Modal
        open={openCreateContact}
        onClose={handleCloseCreateContact}
        aria-labelledby="creation-note-title"
        aria-describedby="creation-note-description"
        style={{ zIndex: "99999" }}
      >
        <CreateContact
          closeIt={handleCloseCreateContact}
          numParcelle={crmLandId}
          onContactCreated={handleContactCreated}
        />
      </Modal>
      <Modal open={createNoteOpen}>
        <CreateNoteDynamic
            ownerType="land"
            ownerId={details.land_id}
            closeIt={() => setCreateNoteOpen(false)}
        />
      </Modal>

    </Paper>
  );
};

export default ParcelResume;
