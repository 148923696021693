import jwtInterceptor from "../jwtInterceptor";
import {LANDS_INTERACTIONS_ENDPOINTS} from "../../utils/api";

class LandsInteractionsService {


    updateFavorite(externalId){
        return jwtInterceptor.post(LANDS_INTERACTIONS_ENDPOINTS.updateFavorite.replace(":externalId", externalId)).then((response) => {
            return response.data.data.landsInteractions
        })
    }

    updateArchive(externalId){
        return jwtInterceptor.post(LANDS_INTERACTIONS_ENDPOINTS.updateArchive.replace(":externalId", externalId)).then((response) => {
            return response.data.data.landsInteractions
        })
    }
}

export const landsInteractionsService = new LandsInteractionsService()