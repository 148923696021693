import { useTransactionsAndFaos } from "../../../Context/crm/TransactionsAndFaosContext"
import { Badge, Box, Chip, Link } from "@mui/material"
import Transaction from '../../../assets/Images/icons/enQt.svg'
import CustomBtn from "../../customBtns/CustomBtn"
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';

const TransactionsBadge = ({onClick}) => {
    const {transactions} = useTransactionsAndFaos()

    return (
        <Box sx={{marginRight: "15px"}}>
          <Link to="#transaction" onClick={onClick} sx={{cursor: "pointer" , marginInline: {xl : 0, lg: 0, md: 0 , sm: 0 ,xs:1}}}>
            <Badge badgeContent={transactions.length > 0 ? transactions.length : '0'} color="error" size="small"   anchorOrigin={{  vertical: 'top', horizontal: 'left', }}>
            <CustomBtn
                          bgColor={"#6E3FB6"}
                          label="Transactions"
                          iconBg={"#6E3FB6"}
                          icon={<PaidOutlinedIcon />}
                      />
            </Badge>
          </Link>
        </Box>
    )
}

export default TransactionsBadge