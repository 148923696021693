import { ELASTIC_LAND_ENDPOINTS } from "../utils/api";
import jwtInterceptor from "./jwtInterceptor";

class LandService {

    async findLandById(landId) {
        try{
            if(!landId){
                throw new Error('landId is required')
            }
            const response = await jwtInterceptor.get(ELASTIC_LAND_ENDPOINTS.findById.replace(':landId', landId))
            return response.data.data
        }    
        catch(error){
            throw new Error(error)
        }
    }
} 

export const landService = new LandService();