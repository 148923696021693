import { createContext, useContext, useState } from "react";
import jwtInterceptor from "../../service/jwtInterceptor";
import { CONTACT_ENDPOINTS } from "../../utils/api";

const ContactContext = createContext();

export const useContact = () => useContext(ContactContext);

export const ContactProvider = ({children}) => {
    const [contact, setContact] = useState(null);

    const fetchContactData = async (contactId) => {
        try{
            const contactResponse = await jwtInterceptor.get(CONTACT_ENDPOINTS.getById.replace(":contactId", contactId))
            const fetchedContact = contactResponse.data.data.records[0]
            setContact(fetchedContact);
            return fetchedContact

        }catch(error){
            Promise.reject(error)
        }
    }

    const refreshContactData = async () => {
        if(contact){
            await fetchContactData(contact._id)
        }else {
            throw new Error("aucun contact dans le hook")
        }
    }

    const addOpportunity = (newOpportunity) => {
        const updatedContact = { ...contact, opportunities: [...contact.opportunities, newOpportunity] };
        setContact(updatedContact);
    } 

    const addNote = (newNote) => {
        const updatedContact = { ...contact, notes: [...contact.notes, newNote] };
        setContact(updatedContact);
    }

    const updateOpportunity = (updatedOpportunity) => {
        const updatedopportunities = contact.opportunities.map((opportunity) => {
            if(opportunity._id === updatedOpportunity._id){
                return updatedOpportunity
            }else{
                return opportunity
            }
        })
        const updatedContact = { ...contact, opportunities: updatedopportunities };
        setContact(updatedContact);

    }

    return (
        <ContactContext.Provider value={{
            contact,
            fetchContactData,
            refreshContactData,
            addOpportunity,
            addNote,
            updateOpportunity
        }}>
            {children}
        </ContactContext.Provider>
    );
}