import {
    Badge,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from "@mui/material";
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import StarIcon from '@mui/icons-material/Star';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import enqueteIcon from '../assets/svg/ListParcelle/enqtIcon.svg'
import Image from 'react-bootstrap/Image'
import { useNavigate } from 'react-router-dom';
import { useApplication } from '../hooks/UseApplication';
import React, { useState, useEffect } from "react";
import {landsInteractionsService} from "../service/crm/LandsInteractionsService";
import {useToast} from "../Context/ToastContext";
import {TABLE_ROW_ACTIONS} from "../utils/constants";
import {ChangeFavoriteDialog} from "./FavoriteBox";
import {ChangeArchiveActionBox} from "./ChangeArchiveActionBox";
import UpdateIcon from "@mui/icons-material/Update";
import {UpdateSharp} from "@material-ui/icons";

function TableRowLand(props) {
  const { row, keyIndex, actions, clickOne = [] , onChange} = props;
  const navigate = useNavigate()
  const { setDetails } = useApplication()
  const [isStarHovered, setIsStarHovered] = useState(false);
  const [openFavoriteDialog, setOpenFavoriteDialog] = useState(false)
  const [openArchiveDialog, setOpenArchiveDialog] = useState(false)
  const toaster = useToast()
  
  /*****custom styles variables*/
  const brown = '#363636'

  const handleStarHover = () => {
    setIsStarHovered(true);
  }
  
  const handleStarLeave = () => {
    setIsStarHovered(false);
  }

  const handleClickOpenAddFavorite = (event) => {
      event.stopPropagation()
      setOpenFavoriteDialog(true)
  }

    const handleClickOpenChangeArchive = (event) => {
        event.stopPropagation()
        setOpenArchiveDialog(true)
    }

    const onCloseFavoriteDialog = (event) => {
        event.stopPropagation()
        setOpenFavoriteDialog(false)
        onChange()
    }

    const onCloseArchiveDialog = (event) => {
        event.stopPropagation()
        onChange()
        setOpenArchiveDialog(false)
    }

    const handleRowClick = () => {
      clickOne();
      // window.open(`/details/${row._source.id}`, '_blank');
    }

    const truncate = (str) => {
      return str.length > 20 ? str.substring(0, 35) + "..." : str;
    };


  return (
    <>
        {}
    <tr sx={{ '& > *': { borderBottom: 'none' } , '&:hover': {backgroundColor: 'lightblue'} }} key={keyIndex} style={{cursor: 'pointer'}} onClick={handleRowClick}>

        {actions && actions.length > 0 ?
        <td align='left' >
          <div class='d-flex  justify-content-center'>
              {(actions.includes(TABLE_ROW_ACTIONS.favorite)) ?
                <div class='mx-2' onClick={handleClickOpenAddFavorite} onMouseEnter={handleStarHover} onMouseLeave={handleStarLeave}>
                    {(row._source.landsInteractions && row._source.landsInteractions.isFavorites) ? <StarIcon style={{ color: 'brown' }} /> : <StarBorderOutlinedIcon style={{ color: 'black' }} />}
                    <ChangeFavoriteDialog row={row._source} open={openFavoriteDialog} onClose={onCloseFavoriteDialog}/>
                </div> : ""
              }
              {(actions.includes(TABLE_ROW_ACTIONS.archive)) ?
                  <div class='mx-2' onClick={handleClickOpenChangeArchive} >
                      {(row._source.landsInteractions && row._source.landsInteractions.isArchived) ? <UpdateIcon style={{ color: 'brown' }} /> : <UpdateSharp style={{ color: 'black' }} />}
                      <ChangeArchiveActionBox row={row._source} open={openArchiveDialog} onClose={onCloseArchiveDialog}/>
                  </div> : ""
              }
          </div>
        </td>
        : ""
        }
        <td>{row._source.code_number}</td>
        <td align="left">{row._source.area} m<sup>2</sup></td>
        <td>
          {row._source.address_street} {row._source.address_street_nb}
        </td>
        <td>
        {row._source.city_name} 
        </td>
        <td>{row._source.region_name}</td>
        <td align="left">{row._source.main_lupa_name}</td>
        <td align="left">{truncate(row._source.owner_list)}</td>
      </tr>
    </>
  );
}

export default TableRowLand;
