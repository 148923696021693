export const formatCurrency = (input) => {
    console.log('input : ', input)
    // Remove non-numeric characters
    let value = input.replace(/\D/g, '');
    console.log('input after replace non-numeric : ', value)
    // Format with thousand separators as whitespace
    value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

    console.log('returned value : ', value)
    return value;
}

export const formatDate = (dateString) => {
    const options = { year: "numeric", month: "numeric", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
};
