import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
 
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
 
import IconButton from "@mui/material/IconButton";
 
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import Nav from "react-bootstrap/Nav";
import { Link, useLocation } from "react-router-dom";
import Image from "react-bootstrap/Image";
import { TravelExplore } from "@mui/icons-material";
import { Contacts } from "@mui/icons-material";
import EarthIcon from "../assets/svg/earthlogo.svg";
import UserSettingLogo from "../assets/svg/UserSetting.svg";
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { useToast } from "../Context/ToastContext";
import { useApplication } from "../hooks/UseApplication";
 
import { useNavigate } from 'react-router-dom';
import { useSidebar } from "../Context/SidebarContext";

const drawerWidth = 240;

const isOpenedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: theme.spacing(0, 1),
  backgroundColor: "#3F50B6",
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "isOpen",
})(({ theme, isOpen }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(isOpen && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "isOpen",
})(({ theme, isOpen }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  backgroundColor: "#3F50B6",
  ...(isOpen && {
    ...isOpenedMixin(theme),
    "& .MuiDrawer-paper": isOpenedMixin(theme),
  }),
  ...(!isOpen && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function SidebarMap({currentPath}) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const toaster = useToast();
  const { logout } = useApplication();
  const navigate = useNavigate();
  const { isOpen, toggleSidebar } = useSidebar();


  const isActive = (path) => {
    return currentPath.startsWith(path);
  };


  const handleClick = () => {
    if (isOpen) {
      handleDrawerOpen();
    } else {
      navigate("/");
    }
  };

  /**** Custom variable style */
  const bluedark = "#3F50B6";
  const fontWeightTitle = {
    fontWeight: "500",
    color: "#fff",
    paddingLeft: isOpen ? "15px" : "5px",
    display: "flex", 
    alignItems: "center",
    margin: 0
  };
  const headerLogoTitle = {
    ml: 3,
  };
  const btnToggle = {
    position: "fixed",
    width: "31px",
    top: "0%",
    left: "77px",
    zIndex: "9999999",
    background: "#3F50B6",
    borderRadius: "0",
    "&:hover" : {
        background: "#3F50B6"
    },
    borderBottomRightRadius: "5px",
    borderTopRightRadius: "5px",
    paddingBlock: "20px",
    '@media (max-width: 767px)': {
        left: "68px",
    }
  }

  const responsiveHeading = {
    borderRight: "none",
    zIndex: "9999999",
    '@media (max-width: 767px)': {
      position: isOpen ? "fixed" : "inherit",
      width: isOpen ? "100%" : "55px!important",
      height: isOpen ? "100%" : "auto",
      "& .MuiDrawer-paper" : {
        width: isOpen ? "100%!important" : "inherit!important",
      }
    },

  };


  

  const IconStyles = isOpen
    ? { fontSize: '18px', width: '18px', color: '#fff' }
    : { fontSize: '24px', width: '24px', color: '#fff' };


  const handleDrawerOpen = () => {
    toggleSidebar();
  };

  const handleLogout = async () => {
    toaster.notifySuccess("Déconnécté avec succès");
    await logout();
  };

  const handleDrawerClose = () => {
    toggleSidebar();
  };

  return (
    <Box sx={{ display: "flex", "& header": {boxShadow: 'none', background: "#f0f8ff00"} }}>
      <CssBaseline />
      <Drawer
        variant="permanent"
        isOpen={isOpen}
        sx={{ "& .MuiPaper-root": { backgroundColor: bluedark, borderRight: "none" } , ...responsiveHeading }}
      >
        <DrawerHeader>
          <Box>
            {isOpen ? (<Nav.Link as={Link}  to={ "/" }><h3 style={fontWeightTitle}>MIXDATA</h3></Nav.Link>) : (<h3 style={fontWeightTitle} onClick={handleDrawerOpen}>M <ChevronRightIcon sx={{ color: "#fff", width: "24px", fontSize:"24px"}}/></h3>) }
          </Box>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon sx={{ color: "#fff", width: "24px", fontSize:"24px", display : isOpen ? 'block' : 'none' }} />
            ) : (
              <ChevronLeftIcon sx={{ color: "#fff", width: "24px", fontSize:"24px", display : isOpen ? 'block' : 'none' }} />
            )}
          </IconButton>
        </DrawerHeader>
        <List className="mt-5">
          <ListItem disablePadding sx={{ display: "block" }}>
            <Nav.Link
              className="text-white d-flex align-items-center"
              as={Link}
              to="/map"
              style={{ backgroundColor: isActive('/land') || isActive('/details/') || isActive('/map/') ? 'rgb(56 64 114 / 55%)' : 'inherit' }}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: isOpen ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: isOpen ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <TravelExplore
                    style={IconStyles}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={"Toutes les parcelles"}
                  sx={{ opacity: isOpen ? 1 : 0 }}
                />
              </ListItemButton>
            </Nav.Link>
          </ListItem>
          <ListItem disablePadding sx={{ display: "block" }}>
            <Nav.Link
              className="text-white d-flex align-items-center"
              as={Link}
              to="/my-land"
              style={{ backgroundColor: isActive('/my-land') ? 'rgb(56 64 114 / 55%)' : 'inherit' }}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: isOpen ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: isOpen ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <Image className="fluid" src={EarthIcon} style={IconStyles}></Image>
                </ListItemIcon>
                <ListItemText
                  primary={"Mes parcelles"}
                  sx={{ opacity: isOpen ? 1 : 0 }}
                />
              </ListItemButton>
            </Nav.Link>
          </ListItem>
          <ListItem disablePadding sx={{ display: "block" }}>
            <Nav.Link
              className="text-white d-flex align-items-center"
              as={Link}
              to="/list-crm"
              style={{ backgroundColor: isActive('/list-crm') || isActive('/detail/') ? 'rgb(56 64 114 / 55%)' : 'inherit' }}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: isOpen ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: isOpen ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <Contacts
                    style={IconStyles}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={"Mes contacts"}
                  sx={{ opacity: isOpen ? 1 : 0 }}
                />
              </ListItemButton>
            </Nav.Link>
          </ListItem>
          <ListItem disablePadding sx={{ display: "block" }}>
            <Nav.Link
              className="text-white d-flex align-items-center"
              as={Link}
              to="/user"
              style={{ backgroundColor: isActive('/user') ? 'rgb(56 64 114 / 55%)' : 'inherit' }}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: isOpen ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: isOpen ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <Image className="fluid" src={UserSettingLogo} style={IconStyles}></Image>
                </ListItemIcon>
                <ListItemText
                  primary={"Gestion utilisateur"}
                  sx={{ opacity: isOpen ? 1 : 0 }}
                />
              </ListItemButton>
            </Nav.Link>
          </ListItem>
        </List>
        <List sx={{ position: "absolute", bottom: "0%" }}>
          <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: isOpen ? "initial" : "center",
                  px: 2.5,
                  "& :hover": {
                    background: "none"
                  }
                }}
                onClick={handleLogout}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: isOpen ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {!isOpen && <PowerSettingsNewIcon
                    style={IconStyles}
                  />}
                </ListItemIcon>
                <ListItemText
                  primary={"Se déconnecter"}
                  sx={{ opacity: isOpen ? 1 : 0 , color: "#fff"}}
                />
              </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
    </Box>
  );
}
