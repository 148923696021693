import { z } from "zod"
import jwtInterceptor from "../jwtInterceptor";
import { TACHE_ENDPOINTS } from "../../utils/api";
import { FieldValidationError } from "../../errors/FieldValidationError";

export class TacheService {
    
    taskSchema () {
      return z.object({
            opportunity_id: z.string().nonempty({message: "Champ obligatoire"}),
            goal: z.string().max(50, {message: "50 caractères max"}).nonempty({message: "Champ obligatoire"}),
            description: z.string().max(50, {message: "50 caractères max"}).optional(),
            due_date: z.any().optional(),
            assigned_user_id: z.any().optional(),
            status: z.string({message: "Champ obligatoire"}).nonempty({message: "Champ obligatoire"})
        })  
    } 

    async createTask(taskRequestBody) {
        await this.verifyTaskRequest(taskRequestBody)
        return jwtInterceptor.post(TACHE_ENDPOINTS.new, taskRequestBody).then((response) => {
            if(response.status.toString().startsWith("2")){
                return response.data.data.records[0]
            }else{
                throw new Error(response.data.message)
            }
        })
        .catch((error) => {
            throw new Error(error.message)
        })
    }

    async deleteTask(taskId) {
        if(taskId){

            return jwtInterceptor.delete(TACHE_ENDPOINTS.delete.replace(":taskId", taskId)).then((response) => {
                if(response.status.toString().startsWith("2")){
                    return response.data.data.isDeleted
                }else{
                    throw new Error(response.data.message)
                }
            })
                .catch((error) => {
                    throw new Error(error.message)
                })

        }else {
            throw new Error("taskId ne peut pas etre null ou undefined")
        }
    }

    async verifyTaskRequest(taskRequestBody){
        try {
            this.taskSchema().parse(taskRequestBody);
        } catch (error) {
            if (error instanceof z.ZodError) {
                const validationErrors = {};
                error.errors.forEach((err) => {
                    const fieldName = err.path[0];
                    validationErrors[fieldName] = err.message;
                });

                throw new FieldValidationError("probleme lors de la verification des champs", validationErrors) 
            }
            else{
                throw error
            }
        }
         
    }

}

export const taskService = new TacheService()
