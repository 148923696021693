import {
  Table,
  TableRow,
  TableBody,
  TableHead,
  TableCell,
  Box,
  Chip,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import CustomBtn from "../customBtns/CustomBtn";
import Etat from "../etatComponent/etatComponent.";
import PlaceIcon from "@mui/icons-material/Place";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import Image from "react-bootstrap/Image";
import FilterIcon from "@assets/svg/param.svg";
import { Link } from "react-router-dom";
import Modal from "@mui/material/Modal";
import CreateContact from "../detailcrm/modals/creationcontact";
import jwtInterceptor from "../../service/jwtInterceptor";
import {CONTACT_ENDPOINTS, LAND_ENDPOINTS} from "../../utils/api";
import { useToast } from "../../Context/ToastContext";
import axios from "axios";
import {Helmet} from "react-helmet";

const ListCrm = () => {
  const [contacts, setContacts] = useState([]);
  const [openCreateContact, setOpenCreateContact] = useState(false);

  const toaster = useToast();

  const handleCloseContact = () => {
    setOpenCreateContact(false);
    fetchData();
  };

  const handleContactCreated = (newContact) => {
    // Ajoutez le nouveau contact à la liste
    setContacts((prevContacts) => [...prevContacts, newContact]);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    jwtInterceptor
      .get(CONTACT_ENDPOINTS.search)
      .then((response) => {
        setContacts(response.data.data.records);
      })
      .catch((error) => {
        toaster.notifyError(error.message);
      });
  };


  const handleOpenCreateContact = () => setOpenCreateContact(true);

  // Custom styles

  const boxedStyles = {
    background: "#fff",
    borderRadius: "8px",
    padding: "25px", 
  };
  const tableBodyStyles = {
    height: "auto",
    overflow: "hidden",
  };
  const tableHeadStyles = {
    background: "#F5F5F5",
  };
  const fontWeightMedium = {
    fontWeight: "500!important",
  };
  const fontWeightTitle = {
    fontWeight: "600",
  };
  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 500,
      background: "#F9FAFB",
    },
  });


  return (
    <Box sx={boxedStyles} className="my-4 mx-4">
      <Helmet>
        <title>Mes contacts - Mixdata</title>
      </Helmet>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "baseline",
        }}
      >
        <h3 className="mb-4" style={fontWeightTitle}>
          Mes contacts
        </h3>

        <Box>
          <CustomBtn
            label="contact"
            bgColor="#299CDB"
            iconBg={"#3ba9e5"}
            icon={"+"}
            click={handleOpenCreateContact}
          />
          {/* <Image src={FilterIcon} className="fluid ms-3 pointer"></Image> */}
        </Box>
      </Box>
      <Box sx={{ overflow: "auto" }}>
        <Table className="crm" size="small">
          <TableHead sx={tableHeadStyles}>
            <TableRow>
              <TableCell sx={fontWeightMedium}>Contact</TableCell>
              <TableCell sx={fontWeightMedium}>Type</TableCell>
              <TableCell sx={fontWeightMedium}>Parcelle</TableCell>
              <TableCell sx={fontWeightMedium}>Nb Affaires</TableCell>
              <TableCell sx={fontWeightMedium}>Nb Notes</TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={tableBodyStyles}>
            {contacts.length > 0 &&
                contacts.map(
                (contact, index) =>
                   (
                    <React.Fragment key={contact.id}>
                        <TableRow
                          sx={{
                            borderTop:
                              "1px solid rgba(224, 224, 224, 1)!important",
                          }}
                        >
                          <TableCell>
                              <Link
                                to={`/detail/${contact.lands[0]._id}/${contact._id}`}
                                style={{ textDecoration: "none" }}
                              >
                                {contact.firstName +
                                    (contact.lastName
                                      ? " " + contact.lastName
                                      : "")}
                              </Link>
                          </TableCell>
                          <TableCell> 
                            {(contact.type) ||
                            (contact.types) ? (
                              <Chip
                                sx={{ background: "#3F50B6", color: "#fff" }}
                                label={
                                  (contact.type) ||
                                  (contact.types)
                                }
                              />
                            ) : (
                              <></>
                            )}
                          </TableCell>
                          <TableCell>
                            <Box
                              sx={{
                                display: "flex",
                              }}
                            >
                              {contact.lands[0].resume.code_number} (
                              {contact.lands[0].resume.area &&
                              contact.lands[0].resume.area > 0 ? (
                                <span>
                                  {contact.lands[0].resume.area} m<sup>2 </sup>
                                </span>
                              ) : (
                                ""
                              )}
                              {contact.lands[0].resume.city_name ? (
                                <span>
                                  {" "}
                                  &nbsp;-&nbsp;{contact.lands[0].resume.city_name}
                                </span>
                              ) : (
                                ""
                              )}
                              {contact.lands[0].resume.region_name ? (
                                <span>
                                  {" "}
                                  &nbsp;-&nbsp;{contact.lands[0].resume.region_name}
                                </span>
                              ) : (
                                ""
                              )}
                              )
                            </Box>
                          </TableCell>
                          <TableCell>
                            {contact.opportunities
                              ? contact.opportunities.length
                              : 0}
                          </TableCell>
                          <TableCell>
                            {contact.notes
                              ? contact.notes.length
                              : 0}
                          </TableCell>
                        </TableRow>
                    </React.Fragment>
                  )
              )}
          </TableBody>
        </Table>
        <Modal
          open={openCreateContact}
          onClose={handleCloseContact}
          aria-labelledby="creation-contact-title"
          aria-describedby="creation-contact-description"
        >
          <CreateContact closeIt={handleCloseContact} onContactCreated={handleContactCreated} />
        </Modal>
      </Box>
    </Box>
  );
};
export default ListCrm;
