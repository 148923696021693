import { Box, Card, Paper, Typography, Button, IconButton , Collapse } from "@mui/material";
import Form from "react-bootstrap/Form";
import React, { useEffect, useRef, useState } from "react";
import TextField from "@mui/material/TextField";
import RefreshIcon from '@mui/icons-material/Refresh';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

export const MyLandsMapFilterCard = ({ handleSearch }) => {
  const [landsCodeNumber, setLandsCodeNumber] = useState("");
  const [zoneAffectation, setZoneAffectation] = useState("");
  const [commune, setCommune] = useState("");
  const [address, setAddress] = useState("");
  const [owner, setOwner] = useState("");
  const [areaMin, setAreaMin] = useState(null);
  const [areaMax, setAreaMax] = useState(null);
  const [showAllFields, setShowAllFields] = useState(false);

  const onClickValidate = () => {
    let newFilters = {};
    if (landsCodeNumber) newFilters.landsCodeNumber = landsCodeNumber;
    if (commune) newFilters.commune = commune;
    if (zoneAffectation) newFilters.zoneAffectation = zoneAffectation;
    if (address) newFilters.address = address;
    if (owner) newFilters.owner = owner;
    if (areaMin && areaMax) {
      newFilters.areaMin = areaMin;
      newFilters.areaMax = areaMax;
    }

    newFilters.size = 10;
    newFilters.page = 0;

    console.log("new filters", newFilters);

    handleSearch(newFilters);
  };

  const onClickClean = () => {
    setCommune("");
    setAreaMin("");
    setAreaMax("");
    setOwner("");
    setAddress("");
    setZoneAffectation("");
    setLandsCodeNumber("");

    handleSearch([]);
  };

  return (
    <>



    {/* vertical */}
    <Card
      className="shadow-none"
      style={{ padding: "20px", backgroundColor: "#fff", height: "100%" }}
    >
      <Box component="form">
        <Form.Group className="my-3" controlId="1" key="landsCodeNumber">
          <TextField
            type="text"
            controlId="1"
            label="N° Parcelle"
            placeholder="N° Parcelle"
            fullWidth
            value={landsCodeNumber}
            onChange={(e) => setLandsCodeNumber(e.target.value)}
            size="small"
          />
        </Form.Group>
        <Form.Group className="my-3" controlId="5" key="Commune">
          <TextField
            type="text"
            controlId="5"
            label="Commune"
            placeholder="Commune"
            fullWidth
            value={commune}
            onChange={(e) => setCommune(e.target.value)}
            size="small"
          />
        </Form.Group>
        <IconButton
            onClick={() => setShowAllFields(!showAllFields)}
            sx={{"&:hover" : {background: "none" }, p: 0}}
          >
            {showAllFields ? <><span style={{fontSize: "14px"}}>Afficher moins</span> <ExpandLessIcon /> </> : <><span style={{fontSize: "14px"}}>Filtre avancé</span><ExpandMoreIcon /></>}
        </IconButton>
        <Collapse in={showAllFields} timeout="auto" unmountOnExit>
        <Form.Group className="my-3" controlId="3" key="address">
          <TextField
            type="text"
            controlId="3"
            label="Adresse"
            placeholder="Adresse"
            fullWidth
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            size="small"
          />
        </Form.Group>
        <Form.Group className="my-3" controlId="5" key="zoneAffectation">
          <TextField
            type="text"
            controlId="5"
            label="Zone d'affectation"
            placeholder="Zone d'affectation"
            fullWidth
            value={zoneAffectation}
            onChange={(e) => setZoneAffectation(e.target.value)}
            size="small"
          />
        </Form.Group>
        <Form.Group className="my-3" controlId="6" key="owner">
          <TextField
            type="text"
            controlId="6"
            label="Propriétaire"
            placeholder="Propriétaire"
            fullWidth
            value={owner}
            onChange={(e) => setOwner(e.target.value)}
            size="small"
          />
        </Form.Group>
        <Form.Group className="my-3" controlId="6" key="area">
          <Form.Label>Surface</Form.Label>
        <Box sx={{display: "flex", justifyContent: "space-between"}}>
          <TextField
            type="number"
            controlId="6"
            label="min"
            placeholder="min"
            InputProps={{ inputProps: { min: 1 } }}
            fullWidth
            value={areaMin}
            onChange={(e) => setAreaMin(e.target.value)}
            sx={{ mb: 1, width: "47%" }}
            size="small"
          />
          <TextField
            type="number"
            controlId="6"
            label="max"
            placeholder="max"
            InputProps={{ inputProps: { min: 2 } }}
            fullWidth
            value={areaMax}
            onChange={(e) => setAreaMax(e.target.value)}
            sx={{width: "47%"}}
            size="small"
          />
          </Box>
        </Form.Group>
        </Collapse>
        <Box sx={{ display: "flex", justifyContent: "flex-end", pt: 2 }}>

        <Button
          sx={{
            background: "#757575",
            color: "#fff",
            "&:hover": { background: "#757575" },
            display:"flex",
            alignItems: "center",
            justifyContent: "space-between",
            verticalAlign: "center"
          }}
          onClick={() => onClickClean()}
        >
          <RefreshIcon sx={{mr: 1, fontSize: 18}} />
          REINITIALISER
        </Button>
        <Button
          sx={{
            background: "#299CDB",
            color: "#fff",
            "&:hover": { background: "#299CDB" },
            ml: 1,
          }}
          onClick={() => onClickValidate()}
        >
          Filtrer
        </Button>
        </Box>
      </Box>
    </Card>
    </>
  );
};
