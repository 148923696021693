import jwtInterceptor from "../jwtInterceptor";
import {CONTACT_ENDPOINTS} from "../../utils/api";

export class ContactService {

    async findByExternalLandId(externalLandId) {
        return jwtInterceptor.get(CONTACT_ENDPOINTS.getByExternalLandId.replace(':externalLandId', externalLandId)).then((response) => {
            return response.data.data.records
        })
    }
}

export const contactService = new ContactService()