import jwtInterceptor from "../jwtInterceptor";
import {LAND_ENDPOINTS} from "../../utils/api";

export class LandService {
    getMyLand(){
        return jwtInterceptor
            .get(LAND_ENDPOINTS.list)
            .then((response) => {
                return response.data.data.records;
            })
            .catch((error) => {
                throw error;
            });
    }

    isManaged (landId) {
        return jwtInterceptor
            .get(LAND_ENDPOINTS.isManaged.replace(':landId', landId))
            .then((response) => {
                return response.data.data.landId;
            })
            .catch((error) => {
                throw error;
            });
    }
}

export const landService = new LandService();