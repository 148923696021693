import React from "react";
import { Box , Accordion , AccordionSummary , Typography , AccordionDetails } from "@mui/material";
import { detailparcelleStyles } from "./styles";
import jwtInterceptor from "../../../service/jwtInterceptor";
import { useState , useEffect } from "react";
import { useApplication } from "../../../hooks/UseApplication";
import { CircularProgress } from "@mui/material";
import { API_URL } from "@utils/constants";

/*****Ui icons */
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTransactionsAndFaos } from "../../../Context/crm/TransactionsAndFaosContext";

const TransactionDetail = () => {
    const {setTransactions, transactions} = useTransactionsAndFaos()
    const generalId = useApplication();
    const gId = generalId.details.land_id;
    const [loading, setLoading] = useState(true)
    const [expanded, setExpanded] = React.useState('panel1');
    const handleChanges = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const fetchData = async () => {
        try {
            setLoading(true)
            if(gId){
                const response = await jwtInterceptor.get(
                    `${API_URL}/transaction/land/${gId}`,
                    {
                    size: 2000,
                    page: 1,
                    region: "all",
                    }
                );
                const data = response.data.data.results;
                console.log('gId inside transaction', gId)
                console.log('data inside transaction', data)
                const matchingTransactions = await data.filter((transac) => transac._source.lands_id === gId.toString());
                setTransactions(matchingTransactions);    
            }
        } catch (error) {
          console.error("Erreur lors de la requête API :", error);

        }
    };

    useEffect(() => {
        fetchData();
    } , [gId])
    
    useEffect(() => {
        setLoading(false)
      }, [transactions])

    return(
        <Box className="Layout bg-white my-4 rounded p-4" id="transaction">
            <Typography variant="h6" style={detailparcelleStyles.fontWeightTitle}>TRANSACTIONS</Typography>
            <Box sx={{p:1 , mt:2}}>

                {
                loading ? (<CircularProgress />) :
                 transactions.length > 0 ? (
                    transactions.map((transaction) => {
                        return(
                            <Accordion
                            key={transaction._id}
                            expanded={expanded === transaction._id}
                            onChange={handleChanges(transaction._id)}
                        >
                            <AccordionSummary
                                aria-controls="panel1d-content"
                                id="panel1d-header"
                                expandIcon={<ExpandMoreIcon />}
                                sx={detailparcelleStyles.accordions}
                            >
                                <Typography sx={{ width: "33.3%", textAlign: "start", ml: 5, color: "#299CDB", fontWeight: "bold" }}>
                                    Acheteur(s) : {transaction._source.buyers}
                                </Typography>
                                <Typography sx={{ width: "33.3%", textAlign: "center" }}>
                                    Type : {transaction._source.reason}
                                </Typography>
                                <Typography sx={{ width: "33.3%", textAlign: "right", color: "#299CDB", fontWeight: "bold" }}>
                                    {transaction._source.date}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails sx={{ background: "#FAFAFA" }}>
                                <Box className="col-12">
                                <AccordionDetails sx={{ background: "#FAFAFA" }}>
                                    <Box className="col-12">
                                        <Box className="d-flex justify-content-between p-4 overflow-auto" style={detailparcelleStyles.grayBg}>
                                            <Box className="mx-2 w-50">
                                                <p><span style={{ color: detailparcelleStyles.graylight, ...detailparcelleStyles.fontWeightTitle }}>Identification : </span> {transaction._source.official_id}</p>
                                                <p><span style={{ color: detailparcelleStyles.graylight, ...detailparcelleStyles.fontWeightTitle }}>Type de bien: </span> {transaction._source.ownership_type}</p>
                                                <p><span style={{ color: detailparcelleStyles.graylight, ...detailparcelleStyles.fontWeightTitle }}>Information : </span> {transaction._source.land_information}</p>
                                                <p><span style={{ color: detailparcelleStyles.graylight, ...detailparcelleStyles.fontWeightTitle }}>Ville :</span> {transaction._source.city}</p>
                                                <p><span style={{ color: detailparcelleStyles.graylight, ...detailparcelleStyles.fontWeightTitle }}>Adresse :</span> <br />{transaction._source.address}</p>
                                            </Box>
                                            <Box className="mx-2 w-50">
                                            <p><span style={{ color: detailparcelleStyles.graylight, ...detailparcelleStyles.fontWeightTitle }}>Acheteurs :</span> {transaction._source.buyers}</p>
                                                <p><span style={{ color: detailparcelleStyles.graylight, ...detailparcelleStyles.fontWeightTitle }}>Vendeurs :</span> {transaction._source.sellers}</p>
                                                <p><span style={{ color: detailparcelleStyles.graylight, ...detailparcelleStyles.fontWeightTitle }}>Prix : </span> {transaction._source.price ? `${transaction._source.price} €` : 'N/A'}</p>
                                                <p><span style={{ color: detailparcelleStyles.graylight, ...detailparcelleStyles.fontWeightTitle }}>Détails :</span> <br />{transaction._source.details}</p>
                                                <p><span style={{ color: detailparcelleStyles.graylight, ...detailparcelleStyles.fontWeightTitle }}>Date de transaction :</span> {transaction._source.date}</p>
                                            </Box>
                                        </Box>
                                    </Box>
                                </AccordionDetails>
                                </Box>
                            </AccordionDetails>
                        </Accordion>
    
                        )
                    }
                    )) : (
                        <Typography variant="body1">Pas de transaction disponible</Typography>
                    )
                }
            </Box>
        </Box>
    )
}

export default TransactionDetail;