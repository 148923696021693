import React, {useEffect, useState} from "react";
import { Box, Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import { ajouterNote } from "../../../indexedDb";
import { ToastContainer, toast } from "react-toastify";
import { useToast } from "../../../Context/ToastContext";
import { useContact } from "../../../Context/crm/ContactContext";
import note from "../note/note";

const CreateNote = ({ closeIt, note }) => {
  const {contact, addNote} = useContact()
  const [description, setDescription] = useState("");
  const [_id, set_Id] = useState(undefined);
  const toaster = useToast()
  const [isUpdate, setIsUpdate] = useState(false)

  //Toasts
  const champsvide = () =>
    toast.warn("Veuillez compléter les champs obligatoires");
  const successtoast = () => toaster.notifySuccess(isUpdate ? "Note modifiée avec succès" : "Note créé avec succès");

  useEffect(() => {
    if(note) {
      setIsUpdate(true)
      setDescription(note.description)
      set_Id(note._id)
    }
  }, [note]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const ajouterNouvelleNote = async () => {
    const date_create = new Date();
    console.log('description', description)
    console.log('conatct', contact)
    if (!description) {
      champsvide();
    } else {

      try{
        const newNote = await ajouterNote(description, contact._id, date_create, 'contact', _id);
        addNote(newNote);
        successtoast();
        closeIt();

      }catch(error){
        toaster.notifyError("insertion KO : " + error.message)
      }
    }
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Box sx={style}>
        <h4 style={{ marginBottom: 20 }}>Ajouter une note</h4>
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { my: 1, maxWidth: "436px" },
          }}
          noValidate
          autoComplete="off"
        >
          <textarea
            required
            id="outlined-required"
            label="Description"
            style={{width : "100%"}}
            rows={4}
            size="medium"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          <Box sx={{ display: "flex", justifyContent: "flex-end", pt: 2 }}>
            <Button
              sx={{
                background: "#757575",
                color: "#fff",
                "&:hover": { background: "#757575" },
                mr: 2,
              }}
              onClick={closeIt}
            >
              Annuler
            </Button>
            <Button
              sx={{
                background: "#299CDB",
                color: "#fff",
                "&:hover": { background: "#299CDB" },
              }}
              onClick={ajouterNouvelleNote}
            >
              Ajouter
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default CreateNote;
