import {
  GoogleMap,
  InfoWindowF,
  LoadScript,
  Marker,
  MarkerF,
  PolygonF,
} from "@react-google-maps/api";
import React, { useEffect, useRef, useState } from "react";
import ParcelResume from "../parcelResume/ParcelResume";
import { Box, CircularProgress } from "@material-ui/core";
import yellowStarMarker from "./../../assets/Images/icons/marker-star-yellow.png"

export const LandsMap = ({ lands, options }) => {
  //suisse
  const center = {
    lat: 46.2044,
    lng: 6.1432,
  };

  const defaultZoom = 12

  const [clickedLands, setClickedLands] = useState([]);

  const [customOptions, setCustomOptions] = useState({});
  const [landsMarker, setLandsMarker] = useState([]);
  const [landsPolygon, setLandsPolygon] = useState([]);
  const apiKey = "AIzaSyBav4VWXM20D6RGGbuDuMvPDG2wIu1wbWs";
  const mapRef = useRef(null);

  const [bounds, setBounds] = useState();
  const [showResume, setShowResume] = useState(false);
  const [selectedLandIdResume, setSelectedLandIdResume] = useState();

  const containerStyle = {
    width: "100%",
    height: "100%",
  };
  const mapStyles = [
    {
      featureType: "poi",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "transit",
      stylers: [{ visibility: "off" }],
    },
  ];

  const [defaultOptions, setDefaultOptions] = useState({});

  useEffect(() => {
    setClickedLands([])
    refreshMarkers(lands);
    if(mapRef?.current && lands && lands.length === 0) {
      mapRef.current.setCenter(center)
      mapRef.current.setZoom(defaultZoom)
    }
  }, [lands, bounds]);

  useEffect(() => {
    setCustomOptions(options ?? defaultOptions);
  }, [options]);

  const onLoad = (map) => {
    console.log("maps loaded");
    mapRef.current = map;
    map.setCenter(center);
    setBounds(new window.google.maps.LatLngBounds());
    setDefaultOptions({
      styles: mapStyles,
      disableDefaultUI: false, // Enable default UI
      zoomControl: true, // Enable zoom control
      streetViewControl: true, // Enable street view control
      mapTypeControl: true, // Enable map type control
      fullscreenControl: true, // Enable fullscreen control
      scaleControl: true, // Enable scale control
      rotateControl: true, // Enable rotate control
      controlSize: 24, // Control size
      mapTypeId: "roadmap",
      mapTypeControlOptions: {
        style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
        position: window.google.maps.ControlPosition.TOP_CENTER,
      },
      zoomControlOptions: {
        position: window.google.maps.ControlPosition.RIGHT_BOTTOM,
      },
      streetViewControlOptions: {
        position: window.google.maps.ControlPosition.RIGHT_BOTTOM,
      },
      fullscreenControlOptions: {
        position: window.google.maps.ControlPosition.LEFT_BOTTOM,
      },
      rotateControlOptions: {
        position: window.google.maps.ControlPosition.RIGHT_BOTTOM, // Position of the control
      },
      heading: 0,
      tilt: 0,
    });
  };

  const refreshMarkers = (lands) => {
    setLandsMarker([]);
    setLandsPolygon([]);
    if (bounds && lands) {
      let newLandsMarker = [];

      lands.map((land) => {
        let landMarker;
        const landsInteractions = land._source.landsInteractions

        if (land._source.geo_center && landsInteractions?.isFavorites) {
          landMarker = buildMarker(land)
        }

        if(landMarker) {
          newLandsMarker.push(landMarker)
        }
      })

      setLandsMarker(newLandsMarker);

      if (lands && lands.length > 0 && mapRef.current) {
        buildPolygons();
      }

    }
  };

  const buildMarker = (land) => {
    let landMarker

    const lat = land._source.geo_center.lat;
    const long = land._source.geo_center.lon;
    const icon = getMarkerIcon(land)

    if (lat && long) {
      landMarker = {
        _id: land._id,
        id: land._source.land_id,
        key: land._source.code_number,
        position: {
          lat: lat,
          long: long,
        },
        title: land._source.address_full ?? "",
        icon: icon,
        resume: {
          streetNumber: land._source.address_street_nb,
          streetName: land._source.address_street,
          zone: land._source.main_lupa_name,
          commune: land._source.city_name,
          code: land._source.code_number,
          surface: land._source.area,
        },
      };
    }

    return landMarker
  }

  const handleMarkerClick = (land) => {
    setClickedLands([...clickedLands, land._id]);
  };

  const buildPolygons = () => {
    const newPolygons = [];
    const newBounds = new window.google.maps.LatLngBounds()
    lands.map((land) => {
      const coordinatesData =
        land._source.geo_polygon &&
        land._source.geo_polygon.coordinates.length > 0 &&
        land._source.geo_polygon.coordinates[0].length > 0 &&
        land._source.geo_polygon.coordinates[0][0].length > 0
          ? land._source.geo_polygon.coordinates[0][0]
          : [];
      const color = getPolygonColor(land._source)

      if (coordinatesData.length > 0) {
        let coordinates = [];
        const options = {
          fillColor: color,
          fillOpacity: 0.35,
          strokeColor: "blue",
          strokeOpacity: 1.0,
          strokeWeight: 1,
        };

        coordinatesData.map((polygonData) => {
          const lat = polygonData[1];
          const long = polygonData[0];
          if (lat && long) {
            coordinates.push({
              lat: lat,
              lng: long,
            });
          }
        });

        newPolygons.push({
          coordinates: coordinates,
          options: options,
          id: land._source.land_id, // Ajoutez l'ID du terrain ici
        });

        const lat = land._source.geo_center.lat
        const long = land._source.geo_center.lon
        newBounds.extend(new window.google.maps.LatLng(lat, long))
      }
    });

    console.log("newPolygons", newPolygons);
    setLandsPolygon(newPolygons);
    if(newPolygons && newPolygons.length > 0) {
      mapRef.current.fitBounds(newBounds)
      if(newPolygons.length === 1) mapRef.current.setZoom(20)
    }
  };

  const getPolygonColor = (land) => {
    let color = 'blue'

    if(land.landsInteractions?.isArchived) color = 'red'
    else if((land.landsInteractions) && ((land.landsInteractions.notes && land.landsInteractions.notes.length > 0) || land.landsInteractions.isManaged)) color = 'green'

    return color
  }
  const onPolygonClick = (polygon) => {
    const land = lands.filter((l) => l._source.id === polygon.id).shift();
    if (land) {
      setClickedLands([buildMarker(land)]); // Assurez-vous d'afficher les marqueurs associés
      setShowResume(true); // Afficher le résumé du terrain
      setSelectedLandIdResume(land._source.id); // Définir l'ID du terrain sélectionné
    }
  };

  const getMarkerIcon = (land) => {
    let url = ""
    if(land._source.landsInteractions?.isFavorites) {
      url = yellowStarMarker
    }else {
      url = `http://maps.google.com/mapfiles/ms/icons/red-dot.png`
    }

    return {
      url : url,
      scaledSize : {
        width: 30,
        height : 30
      }
    };
  };

  const toggleResume = () => {
    setShowResume((prevShowResume) => !prevShowResume);
  };

  //styles

  const hideTitleDialog = {
    "& .gm-style-iw-chr": {
      display: "none",
    },
  };

  return (
    <>
      <LoadScript
        googleMapsApiKey={apiKey}
        loadingElement={
          <CircularProgress
            color="secondary"
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          />
        }
      >
        <GoogleMap
          mapContainerStyle={containerStyle}
          ref={mapRef}
          onLoad={onLoad}
          zoom={defaultZoom}
          options={defaultOptions}
        >
          {clickedLands.map((land) => {
            if (land) {
              return (
                <MarkerF
                  key={land._id}
                  position={{ lat: land.position.lat, lng: land.position.long }}

                />
              );
            }
            return null;
          })}

          {landsMarker.map((land) => (
              <MarkerF
                  icon={land.icon}
                  key={land._id}
                  position={{ lat: land.position.lat, lng: land.position.long }}
              />
          ))
          }

          {landsPolygon.map((polygon) => (
            <PolygonF
              key={polygon.id}
              paths={polygon.coordinates}
              options={polygon.options}
              onClick={() => onPolygonClick(polygon)} // Appeler la fonction de clic
            />
          ))}
        </GoogleMap>
      </LoadScript>
      <Box className={`resume ${showResume ? "open" : "closed"}`}>
        <ParcelResume
          closeFenetre={toggleResume}
          landId={selectedLandIdResume}
        />
      </Box>
    </>
  );
};
