import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Container,
  Avatar,
  Typography,
  Chip,
  Badge,
  Button,
  Collapse,
  ListItemIcon,
} from "@mui/material";
import DetailCrmApi from "./staticapi/contacts.json";
import ExistingParcels from "./staticapi/existingparcels.json";
import CustomBtn from "../customBtns/CustomBtn";
import UpdateIcon from "@mui/icons-material/Update";
import DefaultAvatar from "../../assets/Images/detailcrm/defaultAvatar.png";
import VisibilityIcon from "@mui/icons-material/Visibility";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import SmartphoneIcon from "@mui/icons-material/Smartphone";
import EmailIcon from "@mui/icons-material/Email";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Divider from "@mui/material/Divider";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import enqueteIcon from "../../assets/svg/ListParcelle/enqtIcon.svg";
import Image from "react-bootstrap/Image";
import { Link } from "react-router-dom";
import Affaires from "./affaire/affaire";
import CreateAffaire from "./modals/creationaffaire";
import CreateNote from "./modals/creationote";
import Modal from "@mui/material/Modal";
import { getAffaireAssociee, getNotesAssociees } from "../../indexedDb";
import Note from "./note/note";
import ParcelleResume from "./modals/parcellerelies";
import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import PublicIcon from "@mui/icons-material/Public";
import Grid from "@mui/system/Unstable_Grid/Grid";

// Tables
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { ContactMailRounded } from "@mui/icons-material";
import jwtInterceptor from "../../service/jwtInterceptor";
import { CONTACT_ENDPOINTS } from "../../utils/api";
import { useContact } from "../../Context/crm/ContactContext";
import ContactNote from "./note/contactNote";
import CreateContact from "./modals/creationcontact";
import { useApplication } from "../../hooks/UseApplication";
import { Helmet } from "react-helmet";
import { Home } from "@mui/icons-material";

const DetailCrm = () => {
  const { setDetails } = useApplication;
  const { id, contactId } = useParams();
  const { contact, fetchContactData, refreshContactData } = useContact();
  const [openAffaire, setOpenAffaire] = React.useState(false);
  const [openNote, setOpenNote] = React.useState(false);
  const [openResume, setOpenResume] = React.useState(false);
  const [parcellescontact, setParcellescontact] = useState([]);
  const [openUpdateContact, setOpenUpdateContact] = useState(false);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleClick = () => {
    setOpen(!open);
  };
  const handleOpenAffaire = () => setOpenAffaire(true);
  const handleCloseAffaire = () => {
    setLoading(true);
    setOpenAffaire(false);
    fetchData().then(() => {
      setLoading(false);
    });
  };
  const handleOpenNote = () => setOpenNote(true);
  const handleCloseNote = () => {
    setOpenNote(false);
    setLoading(true);
    fetchData().then(() => setLoading(false));
  };
  const handleOpenResume = () => setOpenResume(true);
  const handleCloseResume = () => {
    setLoading(true);
    setOpenResume(false);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };
  const [selectedParcel, setSelectedParcel] = useState(null);
  const [loading, setLoading] = useState(true);

  async function fetchData() {
    setLoading(true);

    await fetchContactData(contactId).then((response) => {
      setParcellescontact(response.lands);

      setLoading(false);
    });
  }

  useEffect(() => {
    console.log("contactId inside useEffect detailsCRM", contactId);

    fetchData();
  }, [id, contactId]);

  const onClickUpdateContact = () => {
    setOpenUpdateContact(true);
  };

  const onCloseUpdateContact = () => {
    fetchData();
    setOpenUpdateContact(false);
  };

  const goToParcel = (parcelleId) => {
    console.log("parcellescontact", parcellescontact);
    parcellescontact.forEach((parcelle) => {
      if (parcelle.externalId == parcelleId) {
        console.log(parcelle);

        window.open(`/details/${parcelle.code}`, "_blank");
      }
    });
  };

  // Custom vars
  const brown = "#363636";
  const containerFullW = {
    "& .MuiContainer-root": { maxWidth: "100%" },
  };

  return loading ? (
    <CircularProgress />
  ) : (
    <Box sx={containerFullW}>
      {contact && (
        <Helmet>
          <title> {`${contact.firstName} ${contact.lastName} - Mixdata`}</title>
        </Helmet>
      )}
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={5} lg={4} xl={3}>
            <Box
              className="my-4"
              sx={{
                background: "#fff",
                padding: "15px",
                borderRadius: "8px",
                paddingInline: "25px",
                marginRight: {
                  xl: "1.5rem",
                  lg: "1.5rem",
                  md: "1.5rem",
                  sm: "0",
                  xs: "0",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  mb: 1,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                    flexWrap: "wrap",
                  }}
                >
                  <h3>
                    {contact.firstName
                      ? `${contact.firstName} ${contact.lastName}`
                      : contact.lastName}
                  </h3>
                  <Chip
                    sx={{ background: "#3F50B6", color: "#fff" }}
                    label={contact.type || contact.types}
                  />
                </Box>
                <Box sx={{ mb: 3, mt: "5px" }}>
                  <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                    <Home sx={{ fontSize: 30 }} />
                    <Typography sx={{ ml: "3px" }}>
                      {contact.address}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                    <LocalPhoneIcon />
                    {contact.phones.filter((phone) => phone.type === "work")[0]
                      ?.number ? (
                      <Link
                        style={{
                          color: "#000",
                          textDecoration: "none",
                          "&:hover": { color: "#299CDB" },
                        }}
                        to={
                          "tel:" +
                          contact.phones.filter(
                            (phone) => phone.type === "work"
                          )[0].number
                        }
                        target="_blank"
                      >
                        <Typography
                          sx={{ ml: 1, "&:hover": { color: "#299CDB" } }}
                        >
                          {
                            contact.phones.filter(
                              (phone) => phone.type === "work"
                            )[0].number
                          }
                        </Typography>
                      </Link>
                    ) : (
                      <Typography sx={{ ml: 1, color: "#000" }}>
                        
                      </Typography>
                    )}
                  </Box>

                  <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                    <SmartphoneIcon />
                    {contact.phones.filter(
                      (phone) => phone.type === "mobile"
                    )[0]?.number ? (
                      <Link
                        style={{
                          color: "#000",
                          textDecoration: "none",
                          "&:hover": { color: "#299CDB" },
                        }}
                        to={
                          "tel:" +
                          contact.phones.filter(
                            (phone) => phone.type === "mobile"
                          )[0].number
                        }
                        target="_blank"
                      >
                        <Typography
                          sx={{ ml: 1, "&:hover": { color: "#299CDB" } }}
                        >
                          {
                            contact.phones.filter(
                              (phone) => phone.type === "mobile"
                            )[0].number
                          }
                        </Typography>
                      </Link>
                    ) : (
                      <Typography sx={{ ml: 1, color: "#000" }}>
                        
                      </Typography>
                    )}
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      mb: contact.website ? 1 : 0,
                    }}
                  >
                    {contact.emails && contact.emails.length > 0 ? (
                      contact.emails.length === 1 ? (
                        <>
                          <EmailIcon sx={{ mr: 1 }} />
                          {contact.emails[0] ? (
                            <Link
                              style={{
                                color: "#212529",
                                display: "flex",
                                textDecoration: "none",
                                "&:hover": { color: "#299CDB!important" },
                              }}
                              to={"mailto:" + contact.emails[0]}
                            >
                              <Typography
                                sx={{
                                  "&:hover": { color: "#299CDB!important" },
                                }}
                              >
                                {contact.emails[0]}
                              </Typography>
                            </Link>
                          ) : (
                            <Typography></Typography>
                          )}
                        </>
                      ) : (
                        <>
                          <List
                            className="email-list"
                            sx={{ display: "flex", alignItems: "flex-start" }}
                          >
                            <ListItem sx={{ width: "auto", mr: 1 }}>
                              <ListItemIcon sx={{ color: "#000" }}>
                                <EmailIcon sx={{ color: "#000" }} />
                              </ListItemIcon>
                            </ListItem>

                            <List
                              className="email-list"
                              component="div"
                              disablePadding
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                width: "100%",
                              }}
                            >
                              {contact.emails.map((email, index) => (
                                <ListItem
                                  className="innerEmailItem"
                                  key={index}
                                  sx={{ pl: 5, width: "auto" }}
                                >
                                  {email ? (
                                    <Link
                                      style={{
                                        color: "#212529",
                                        textDecoration: "none",
                                        "&:hover": { color: "#299CDB" },
                                      }}
                                      to={"mailto:" + email}
                                    >
                                      <ListItemText
                                        primary={
                                          <>
                                            {email}
                                            {index <
                                              contact.emails.length - 1 &&
                                              contact.emails[index + 1] && (
                                                <span
                                                  style={{
                                                    paddingInline: "9px",
                                                  }}
                                                >
                                                  ,
                                                </span>
                                              )}
                                          </>
                                        }
                                        sx={{ "&:hover": { color: "#299CDB" } }}
                                      />
                                    </Link>
                                  ) : (
                                    <Typography></Typography>
                                  )}
                                </ListItem>
                              ))}
                            </List>
                          </List>
                        </>
                      )
                    ) : (
                      <Typography></Typography>
                    )}
                  </Box>

                  {contact.website ? (
                    <Box sx={{ mb: 1 }}>
                      <Link
                        style={{
                          textDecoration: "none",
                          color: "inherit",
                          display: "flex",
                          width: "100%",
                          alignItems: "center",
                        }}
                        to={contact.website}
                        target="_blank"
                      >
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <PublicIcon />
                          <Typography
                            sx={{ ml: 1, "&:hover": { color: "#299CDB" } }}
                          >
                            {contact.website}
                          </Typography>
                        </Box>
                      </Link>
                    </Box>
                  ) : (
                    <></>
                  )}
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-start",
                      mt: 2,
                    }}
                  >
                    {contact.facebook && (
                      <Link
                        style={{
                          textDecoration: "none",
                          color: "inherit",
                          paddingRight: "15px",
                        }}
                        to={contact.facebook}
                        target="_blank"
                      >
                        <FacebookOutlinedIcon sx={{ color: "#1196f5" }} />
                      </Link>
                    )}
                    {contact.linkedin && (
                      <Link
                        style={{
                          textDecoration: "none",
                          color: "inherit",
                          paddingRight: "15px",
                        }}
                        to={contact.linkedin}
                        target="_blank"
                      >
                        <LinkedInIcon sx={{ color: "#0a66c2" }} />
                      </Link>
                    )}
                    {contact.twitter && (
                      <Link
                        style={{
                          textDecoration: "none",
                          color: "inherit",
                          paddingRight: "15px",
                        }}
                        to={contact.twitter}
                        target="_blank"
                      >
                        <TwitterIcon sx={{ color: "#1d9bf0" }} />
                      </Link>
                    )}
                    {contact.whatsapp && (
                      <Link
                        style={{
                          textDecoration: "none",
                          color: "inherit",
                        }}
                        to={contact.whatsapp}
                        target="_blank"
                      >
                        <WhatsAppIcon sx={{ color: "#25d366" }} />
                      </Link>
                    )}
                  </Box>
                </Box>
                <Box
                  sx={{
                    justifyContent: "flex-start",
                    display: "flex",
                    width: "100%",
                  }}
                >
                  <CustomBtn
                    bgColor={"#3F86B6"}
                    label="Modifier le contact"
                    iconBg={"#3F86B6"}
                    mR={"15px"}
                    icon={<ModeEditIcon sx={{ width: "20px" }} />}
                    click={onClickUpdateContact}
                  />
                </Box>
              </Box>
            </Box>
            <Box
              className="my-4"
              sx={{
                background: "#fff",
                padding: "15px",
                borderRadius: "8px",
                paddingInline: "25px",
                marginRight: {
                  xl: "1.5rem",
                  lg: "1.5rem",
                  md: "1.5rem",
                  sm: "0",
                  xs: "0",
                },
              }}
            >
              <Box>
                <h3>Parcelle(s) reliée(s)</h3>
                <TableContainer>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Parcelle</TableCell>
                        <TableCell>Surface</TableCell>
                        <TableCell>Adresse</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody sx={{ height: "auto" }}>
                      {parcellescontact.map((parcelle) => (
                        <TableRow
                          key={parcelle.id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            goToParcel(parcelle.externalId);
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {parcelle.resume.code_number}
                          </TableCell>
                          <TableCell>
                            {parcelle.resume.area} m<sup>2</sup>
                          </TableCell>
                          <TableCell>
                            {parcelle.resume.municipality_name}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={7} lg={8} xl={9}>
            <Box
              className="my-4"
              sx={{
                background: "#fff",
                padding: "15px",
                paddingBottom: "25px",
                borderRadius: "8px",
                paddingInline: "25px",
              }}
            >
              <Affaires createAffaire={handleOpenAffaire} />
            </Box>
            <Box
              className="my-4"
              sx={{
                background: "#fff",
                padding: "15px",
                borderRadius: "8px",
                paddingInline: "25px",
              }}
            >
              <ContactNote
                createNote={handleOpenNote}
                contact={contact}
                onUpdate={() => fetchData()}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>

      <Modal
        open={openAffaire}
        onClose={handleCloseAffaire}
        aria-labelledby="creation-affaire-title"
        aria-describedby="creation-affaire-description"
      >
        <CreateAffaire closeIt={handleCloseAffaire} contact={contact} />
      </Modal>
      <Modal
        open={openNote}
        onClose={handleCloseNote}
        aria-labelledby="creation-note-title"
        aria-describedby="creation-note-description"
      >
        <CreateNote closeIt={handleCloseNote} />
      </Modal>
      <Modal
        open={openUpdateContact}
        onClose={onCloseUpdateContact}
        aria-labelledby="modification-contact-title"
        aria-describedby="modification-contact-description"
      >
        <CreateContact
          closeIt={onCloseUpdateContact}
          contact={contact}
          numParcelle={id}
        />
      </Modal>
      <Modal
        open={openResume}
        onClose={handleCloseResume}
        aria-labelledby="creation-Resume-title"
        aria-describedby="creation-Resume-description"
      >
        <ParcelleResume
          selectedParcel={selectedParcel}
          closeIt={handleCloseResume}
        />
      </Modal>
    </Box>
  );
};

export default DetailCrm;
